import React, { useEffect } from 'react';
import styled from 'styled-components';

interface SectionProps {
    
}

export const Section: React.FC<SectionProps> = (props) => {
    return (<>
    {props.children}
    </>);
}
