import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { asset, shuffle } from '../../../app/helpers';
import { useSFX } from '../../../app/hooks/useSFX';
import { useTrans, transVal } from '../../../app/services/i18n';
import { hexToCSSFilters } from '../../../app/theme';
import { IGameMemory } from '../../../types';
import { Button, ButtonBar } from '../../Atoms/Button/Button';
import { Modal, Title, Text } from '../../Modal/Modal';
import { GameEvents } from '../Common';
import { Time } from '../Time';

const Wrapper = styled.div`

`;

const Header = styled.header`
    display: flex;
    gap: 20px;
    justify-content: center;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    margin-top: 28px;
    margin-bottom: 24px;

    > * {
        text-align: center;
        &:first-child { left: 0; color: #00AFAA; }
        &:last-child { right: 0; color: #1DA7E0; }
    }
`;

const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    background-color: #deeee9;

    > * {
        flex: 0 0 33.333%;
    }
`;

const Border = styled.div`
    border: 2px solid #eef7f9;
`;

const Figure = styled.figure<{faceUp?: boolean, faceTile?: string; color: string;}>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    ${props => !props.faceUp ? `
    background: url(${require('./tiles/back.svg').default}) no-repeat center;
    background-size: 60% auto;
    ` : `
    ${hexToCSSFilters(props.color)}
    `}
    ${props => props.faceTile ? `
    background: url(${props.faceTile}) no-repeat center;
    background-size: 75% 75%;
    ` : null}

    &:before {
        content: "";
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
        padding-top: 100%;
    }

    &:after { /* to clear float */
        content: "";
        display: table;
        clear: both;
    }

    > img {
        position: absolute;
        top: 10px;
        bottom: 10px;
        left: 10px;
        right: 10px;
        object-fit: contain;
        object-position: center;
    }
`;

const Picture = styled.figure`
    margin-bottom: 20px;
    max-width: 170px;
    margin: 0 auto;

    > img {
        max-width: 100%;
        ${hexToCSSFilters('#3aa8a3')}
    }
`;

const Centered = styled.div`
    text-align: center;
`;

const TIMEOUT = 60;
const TILES = 12;

interface Tile {
    id: number;
    image: string;
    color: string;
}

export interface MemoryProps extends GameEvents, IGameMemory {
    icons_root: string;
}

export const Memory: React.FC<MemoryProps> = (props) => {
    const { icons_root, onWin, onLoss, tiles: boardTiles, end_card, end_text } = props;

    const { t } = useTrans();
    const sfx = useSFX();
    const [tiles, setTiles] = useState<Tile[]>([]);
    const [faceUp, setFaceUp] = useState<number[]>([]);
    const [selected, setSelected] = useState<number[]>([]);
    const [touches, setTouches] = useState<number>(0);
    const [showEnd, setShowEnd] = useState(false);

    const IMGS = useMemo(() => {
        return boardTiles.map(tile => asset(`${icons_root}/${tile}.svg`))
    }, [boardTiles]);

    const COLORS = ['#3aa8a3', '#0098da', '#919593'];

    useEffect(() => {
        // random generation
        let tiles = [];
        for (let x=0; x<TILES; x++) {
            const id = x % IMGS.length;
            tiles.push({
                id,
                image: IMGS[id],
                color: COLORS[x % COLORS.length]
            });
        }
        tiles = shuffle(tiles);
        setTiles(tiles);
    }, []);

    const endGame = useCallback(() => {
        setShowEnd(true);
    }, []);

    const showTile = useCallback((index: number) => {
        const tile = tiles[index];
        const newSelected = [...selected];
        newSelected.push(index);
        if (newSelected.length <= 2) {
            setSelected(newSelected);
            setTouches(touches => touches+1);
        }
        if (newSelected.length === 2) {
            if (tiles[newSelected[0]].id === tiles[newSelected[1]].id) {
                // right move!
                const newFaceUp = [...faceUp];
                newFaceUp.push(tile.id);
                setFaceUp(newFaceUp);
                setSelected([]);
                sfx.correct();
                // if completed, you win!
                if (newFaceUp.length === IMGS.length) {
                    endGame();
                }
            } else {
                // wrong move
                sfx.wrong();
                window.setTimeout(() => {
                    setSelected([]);
                }, 2000);
            }
        }
    }, [tiles, selected, faceUp]);

    return (<>
        {showEnd && (
            <Modal id='Memory.end'>
                <Title>{t('Memory.end_title')}</Title>
                {end_card && <Picture><img src={asset(`${icons_root}/${end_card}.svg`)} /></Picture>}
                <Centered>
                    {end_text && <Text>{transVal(end_text)}</Text>}
                </Centered>
                <ButtonBar>
                    <Button
                        text={t('Memory.continue')}
                        variant="action"
                        onPress={onWin}
                    />
                </ButtonBar>
            </Modal>
        )}
        <Wrapper>
            <Header>
                <div>{t('Memory.time')}: <Time timeout={TIMEOUT} stop={showEnd} onZero={() => onLoss()} /></div>
                <div>{t('Memory.clicks')}: {touches}</div>
            </Header>
            <Grid>
                {tiles.map((tile, index) => {
                    const shown = faceUp.includes(tile.id) || selected.includes(index);
                    // {shown && (<img src={tile.image} />)}
                    return (<Border>
                        <Figure
                            key={index}
                            color={tile.color}
                            faceUp={shown}
                            faceTile={shown ? tile.image : undefined}
                            onClick={() => showTile(index)}
                        >
                        </Figure>
                    </Border>);
                })}
            </Grid>
        </Wrapper>
    </>);
}
