import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IError } from '../../types';

interface AppState {
    loading?: boolean;
    error?: IError;
}

const initialState: AppState = {
};

export const appReducer = createSlice({
    name: 'app',
    initialState,
    reducers: {
        loading: (state) => {
            state.loading = true;
        },
        failure: (state, action: PayloadAction<IError | undefined>) => {
            state.error = action.payload;
            state.loading = false;
        },
        dismissError: (state) => {
            state.error = undefined;
        },
    },
});

export const { loading, failure, dismissError } = appReducer.actions;

export const selectLoading = (state: RootState): boolean | undefined => state.app.loading;
export const selectError = (state: RootState): IError | undefined => state.app.error;

export default appReducer.reducer