import React, { useCallback, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import bucket_img from './bucket.svg';

export const SIZE = [23.125*1.2, 18.125*1.2]; // percent (320px based)

interface StyledCursorProps {
}

const El = styled.div<StyledCursorProps>`
    position: absolute;
    bottom: 0;
    width: ${SIZE[0]}%;

    > img {
        display: block;
        transform: translateX(-50%);
        width: 100%;
        height: auto;
    }
`;

export interface CursorProps {
    track: number;
    onInit: (width: number, height: number) => void;
    onMove: (x: number) => void;
}

const EVENT_TYPES = ['mousemove', 'touchmove', 'touchstart'];

export const Cursor: React.FC<CursorProps> = (props) => {
    const { track, onInit, onMove } = props;
    const el = useRef<HTMLDivElement>();
    const pos = useRef<number>();

    const callback = useCallback((e: any) => {
        //e.preventDefault();
        const touchstart = e.type === 'touchstart' || e.type === 'touchmove';
        const evt = e; //touchstart ? e.originalEvent : e;
        const x = touchstart ? evt.targetTouches[0].pageX : evt.pageX;
        const y = touchstart ? evt.targetTouches[0].pageY : evt.pageY;
        pos.current = x;
        if (el.current) {
            el.current.style.transform = `translateX(${pos.current}px)`;
            if (pos.current) {
        
                onMove(pos.current / track * 100);
            }
        }
    }, [onMove, track]);

    const dom = useCallback((_el) => {
        if (_el && !el.current && track) {
            el.current = _el;
            EVENT_TYPES.forEach(type => {
                document.addEventListener(type, (e: Event) => callback(e), false);
            });
            onInit(el.current?.offsetWidth as number, el.current?.offsetHeight as number);
        }
    }, [onInit, track, callback]);

    useEffect(() => {
        return () => { 
            EVENT_TYPES.forEach(type => {
                document.removeEventListener(type, callback);
            });
        }
    }, [callback]);

    return <El ref={dom}>
        <img src={bucket_img} width="93" height="58" />
    </El>;
}