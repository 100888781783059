import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { transVal, useTrans, getCurrentLang } from '../../../app/services/i18n';
import { Title, Text, Modal } from '../../Modal/Modal';
import { Media } from '../../Modal/Media';
import { ICode, ID, IPeople, IProject, IVillage } from '../../../types';
import { Button, ButtonBar } from '../../Atoms/Button/Button';
import { Family, PeopleDetails } from '../../Help/People';
import { Gallery } from '../../Modal/Gallery';

const IntroPicture = styled.figure`
    max-width: 240px;
    margin: 0 auto;
    margin-bottom: 20px;

    > img {
        max-width: 100%;
    }
`;

const GiftCode = styled.div`
    text-align: center;
    padding: .5em;
    margin-top: 2rem;
    margin-bottom: 3rem;

    > p {
        font-size: 28px;
        font-weight: bold;
        text-transform: uppercase;
        color: #00A19A;
        margin-bottom: .5rem;
    }
`;

const People = styled.div`
    margin-bottom: 10px;

    > *:not(:last-child) {
        margin-bottom: 32px;
    }
`;

const StyledButtonBar = styled.div`
    margin-top: 2rem;
    text-align: center;

    > button {
        display: inline-flex;
    }
`;

const Centered = styled.div`
    text-align: center;
`;

const FamilyDetails = styled.div`
    font-size: 22px;
    font-weight: bold;
    line-height: 1.16;
    color: #00A19A;
    text-transform: uppercase;
    margin-top: 18px;
    margin-bottom: 18px;
`;

export interface OutroProps {
    project: IProject;
    image: string;
    text: string;
    villages: IVillage[];
    people: IPeople[];
    gift_code?: ICode;
    onChangeProject: (project_id: ID) => void;
    onClose: () => void;
}

export const Outro: React.FC<OutroProps> = ({ project, image, text, villages, people, gift_code, onChangeProject, onClose }) => {
    const [redeem, setRedeem] = useState(false);
    const [details, setDetails] = useState<IPeople>();
    const { t, h } = useTrans();
    const history = useHistory();

    const _people = useMemo(() => {
        return people.filter(p => {
            const v = villages.find(v => v.id === p.village_id);
            return v && v.project_id === project.id && p.village_id === v.id;
        });
    }, [people]);

    const village = useMemo(() => villages.find(v => details && v.id === details.village_id), [villages, details]);

    if (redeem && gift_code) {
        const { data_people: people, data_project: project, data_village: village } = gift_code;
        return (
            <Media
                media={() => people.images ? <Gallery images={people.images} /> : undefined}
                share={t('People.share_url', { people_id: people.id, lang: getCurrentLang()} )}
            >
                <Centered>
                    <Title>{people.name}</Title>
                    <FamilyDetails>
                        {h('Projects.gift_people_data', {
                            project: project?.name || "",
                            village: village?.name || "",
                        })}
                    </FamilyDetails>
                    {people.text && <Text>{transVal(people.text)}</Text>}
                </Centered>
                <ButtonBar>
                    <Button
                        text={t('Projects.gift_go_to_project')}
                        onPress={() => {
                            onChangeProject(project.id);
                        }}
                    />
                </ButtonBar>
                <ButtonBar>
                    <Button
                        text={t('Projects.gift_back')}
                        variant="action"
                        icon='back'
                        onPress={() => setRedeem(false)}
                    />
                </ButtonBar>
            </Media>
        )
    }
    
    if (details) {
        return <PeopleDetails
            village={village}
            project={project}
            details={details}
            onBack={() => setDetails(undefined)}
        />
    }

    return (
        <Modal id='Outro'>
            <IntroPicture>
                <img src={image} />
            </IntroPicture>
            <Text>
                {text}
            </Text>
            {gift_code && <GiftCode
                onClick={() => { setRedeem(true); }}
            >
                <Text>
                    {t('Projects.gift_code_text')}
                </Text>
                <Button>{t('Projects.gift_code_cta')}</Button>
            </GiftCode>}
            <People>
                {_people.map( (family, index) => <Family key={index} {...family} onClick={() => setDetails(family)} />)}
            </People>
            <StyledButtonBar>
                <Button
                    icon={'back'}
                    text={t('Projects.outro_button')}
                    variant="action"
                    onPress={() => onClose()}
                />
            </StyledButtonBar>
        </Modal>
    )
};