import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { INIT_PHASES } from '../../domain/Auth/CheckSession';
import { useTrans } from '../../app/services/i18n';
import { Button } from '../Atoms/Button/Button';

const Wrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #fff;
`;

const Centered = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const Logo = styled.div<{appear: boolean, booted: boolean;}>`
    transition: 1s all;
    opacity: ${props => props.booted ? 0 : 1};
    width: 90vw;
    max-width: 200px;

    > img {
        width: 100%;
    }

    ${props => props.appear ? null : `
        opacity: 0;
        transform: scale(.8);
    `}
`;

const Intro = styled.div<{booted: boolean;}>`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: .5s all;
    opacity: ${props => props.booted ? 1 : 0};

    display: flex;
    justify-content: center;
    text-align: center;
    padding: 45px;
    flex-direction: column;
    background: url(${require('./bg.png').default}) no-repeat;
    background-size: cover;
`;

const IntroTitle = styled.div<{booted: boolean;}>`
    transition: .5s all;
    transition-delay: .5s;
    transform: translateY(${props => props.booted ? '0' : '-20px'});
    opacity: ${props => props.booted ? 1 : 0};
    
    display: inline-block;
    font-size: 52px;
    font-weight: 700;
    line-height: 1.12;
    color: #00AFAA;
    text-transform: uppercase;
    margin-bottom: 32px;
`;

const IntroText = styled.div<{booted: boolean;}>`
    transition: .5s all;
    transition-delay: 1s;
    transform: translateY(${props => props.booted ? '0' : '-20px'});
    opacity: ${props => props.booted ? 1 : 0};

    font-size: 22px;
    font-weight: 400;
    line-height: 1.16;
    margin: 0 auto;
    margin-bottom: 52px;
    max-width: 250px;
`;

const IntroButton = styled.div`
    max-width: 198px;
    margin: 0 auto;
`;

const MessageWrapper = styled.div`
    margin-top: 30vh;
    height: 2em;
    text-align: center;
`;

export interface SplashProps {
    auth: boolean;
    phase?: INIT_PHASES;
    onClose?: () => void;
}

export const Splash: React.FC<SplashProps> = (props) => {
    const { auth, phase, onClose } = props;

    const { t, h } = useTrans();
    const [appear, setAppear] = useState(false);
    const [booted, setBooted] = useState(false);

    useEffect(() => {
        const t1 = setTimeout(() => {
            setAppear(true);
        }, 0);
        const t2 = setTimeout(() => {
            if (auth) {
                if (onClose) onClose();
            } else {
                setBooted(true);
                setAppear(false);
            }
        }, 1000);
        const t3 = setTimeout(() => {
            if (onClose) onClose();
        }, 8000);

        return () => {
            clearTimeout(t1);
            clearTimeout(t2);
            clearTimeout(t3);
        }
    }, []);

    return (<Wrapper onClick={() => { if (onClose) onClose(); }}>
        <Centered>
            <Logo booted={booted} appear={appear}>
                <img src={require('./wami-full.svg').default} />
            </Logo>
        </Centered>
        <Intro booted={booted}>
            <IntroTitle booted={booted}>
                {h('Splash.intro')}
            </IntroTitle>
            <IntroText booted={booted}>
                {h('Splash.text')}
            </IntroText>
            <IntroButton>
                <Button size='big'>
                    {h('Splash.continue')}
                </Button>
            </IntroButton>
        </Intro>
        <Centered>
            <MessageWrapper>
                {/*phase === "trying" && <Spinner />*/}
                {/*phase === "ready" && <>
                    Welcome back {user?.name}!
                    You have {user?.coins} coins.
                    <Link to="/projects">Index projects</Link>
                    <Link to="/code">Insert a new code</Link>
                </>*/}
            </MessageWrapper>
        </Centered>
    </Wrapper>);
}