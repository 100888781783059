import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { IUser } from '../../types';
import { config } from '../../app/services/config';
import { Input } from '../../components/Atoms/Input/Input';
import { Button, ButtonBar, LinkButton } from '../../components/Atoms/Button/Button';
import { dismissError, register, selectError } from '../../app/features/auth';
import { Title, Text, Divider, Modal, IntroText } from '../../components/Modal/Modal';
import { Alert } from '../../components/Alert';
import { MODES } from './Auth';
import { useTrans } from '../../app/services/i18n';

export interface RegisterProps {
    onChangeMode: (mode: MODES) => void;
}

interface FormState {
    firstname: string;
    lastname: string;
    email: string;
    password: string;
    password_confirmation: string;
}

export const Register: React.FC<RegisterProps> = ({onChangeMode}) => {
    const { t } = useTrans();
    const [form, setForm] = useState<Partial<FormState>>({});
    const error = useSelector(selectError);
    const dispatch = useDispatch();

    useEffect(() => {
        if (error) {
            setTimeout(onErrorPurge, 3000);
        }
    }, [error]);

    // input change
    const onChange = useCallback((key: string, value: string) => {
        setForm({...form, [key]: value});
    }, [form]);

    const onSubmit = useCallback(() => {
        dispatch(register(form));
    }, [form]);

    const onErrorPurge = useCallback(() => {
        dispatch(dismissError());
    }, []);

    const inputError = useCallback((name) => {
        if (!error || !error.data) return;
        const err = error.data![name];
        if (err && err.length) return err;
        return;
    }, [error]);

    return (
        <>
            <Modal id='Register' noHeader>
                <Title>{t('Register.title')}</Title>
                <Input
                    label={t('Register.field_firstname')}
                    onChange={(val) => onChange('firstname', val)}
                    errors={inputError('firstname')}
                />
                <Input
                    label={t('Register.field_lastname')}
                    onChange={(val) => onChange('lastname', val)}
                    errors={inputError('lastname')}
                />
                <Input
                    label={t('Register.field_email')}
                    onChange={(val) => onChange('email', val)}
                    errors={inputError('email')}
                />
                <Input
                    label={t('Register.field_password')}
                    type={"password"}
                    onChange={(val) => onChange('password', val)}
                    errors={inputError('password')}
                />
                <Input
                    label={t('Register.field_password_confirmation')}
                    type={"password"}
                    onChange={(val) => onChange('password_confirmation', val)}
                    errors={inputError('password_confirmation')}
                />
                <ButtonBar>
                    <Button
                        text={t('Register.submit')}
                        onPress={() => onSubmit()}
                    />
                </ButtonBar>
                <ButtonBar>
                    <Button
                        text={t('Register.back')}
                        icon='back'
                        variant="action"
                        onPress={() => onChangeMode('login')}
                    />
                </ButtonBar>
            </Modal>
        </>
    );
}
