import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { strSplit, trimSlash } from '../helpers';
import { getStore } from '../store';
import { getToken } from './auth';
import { config } from './config';
import { getCurrentLang } from './i18n';

interface HttpRequestConfig extends AxiosRequestConfig {
    withToken?: boolean;
    withLang?: boolean;
    tryCache?: boolean;
}

function calculatePath(req: HttpRequestConfig) {
    const user = getStore().getState().auth.user;
    if (!user) {
        throw new Error('An user must be provided');
    }
    const url = trimSlash((req.url || "").replace(config.api, ''));
    const payload = {
        url,
        data: req.data || {}
    }
    const raw = JSON.stringify(payload);
    const parts = [ '.api', user.id, url, ...strSplit(btoa(raw), 64) ];
    const path = `${parts.join('/')}.json`;
// console.log(raw, payload, parts, path);
    return path;
}

function requestInterceptorDecorator(target: Object, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;

    descriptor.value = async function (req: HttpRequestConfig) {
        // add authorization
        if (req.withToken) {
            req.headers = {
                ...(req.headers || {}),
                Authorization: `Bearer ${getToken().access_token}`
            };
        }
        if(req.withLang === undefined || req.withLang) {
            req.headers = {
                ...(req.headers || {}),
                Language: getCurrentLang()
            };
            
        }
        // get freezed snapshot
        if (req.tryCache) {
            if (config.api_cache) {
                const path = calculatePath(req);
                try {
                    const res = await axios.get(`${config.api_cache}${path}`);
                    return res;
                } catch(e) {
                }
            }
        }
        const res = originalMethod.apply(this, [req]);
        return res;
    };
}

function responseInterceptorDecorator(target: Object, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;

    descriptor.value = async function (req: HttpRequestConfig) {
        const res = originalMethod.apply(this, [req]);
        /**
         * @todo
         * https://thedutchlab.com/blog/using-axios-interceptors-for-refreshing-your-api-token
         */
        return res;
    };
}

class Http {
    private _instance: AxiosInstance;

    constructor(instance: AxiosInstance) {
        this._instance = instance;
    }

    @requestInterceptorDecorator
    @responseInterceptorDecorator
    request<T = any, R = AxiosResponse<T>> (config: HttpRequestConfig): Promise<R> {
        const defaultConfig = {
            method: 'post',
        } as Partial<HttpRequestConfig>;

        return this._instance.request({
            ...defaultConfig,
            ...config
        });
    }
}

function initHttpClient() {
    const options = {};
    const instance = axios.create(options);
    return new Http(instance);
}

const http = initHttpClient();

export {
    http
};