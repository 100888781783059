import React, { useCallback, useEffect, useRef, useState } from 'react';
import { animateScroll as scroll, scroller } from 'react-scroll';
import styled from 'styled-components';
import { useTrans } from '../../app/services/i18n';
import { vh100 } from '../../app/theme';
import { asset } from '../../app/helpers';
import { Section } from './Section';
import { Base } from './Base/Base';
import { Segment } from './Segment';
import { Decoration } from './Decoration';
import { IProject, IUser } from '../../types';
import { Button } from '../Atoms/Button/Button';
import { config } from '../../app/services/config';

export function pixels2Percent(px: number, axis: 'x' | 'y', canvas: [number, number]) {
    return px / canvas[axis === 'x' ? 0 : 1] * 100;
}

const Container = styled.section`
    margin-bottom: 70px;
`;

const Wrapper = styled.div<{offset: number}>`
    position: relative;
    ${vh100()}
    overflow-x: hidden;
    margin-top: ${props => props.offset}px;
`;

const Footer = styled.footer`
    position: fixed;
    z-index: 2;
    bottom: 20px;
    right: 25px;

    > button {
        border: none;
    }
`;

const Bg = styled.div`
    width: 100%;

    > img {
        width: 100%;
        height: auto;
    }
`;

const BaseWrapper = styled.div<{pos: [number, number], canvas: [number, number]}>`
    position: absolute;
    z-index: 2;
    left: ${props => pixels2Percent(props.pos[0], 'x', props.canvas)}%;
    top: ${props => pixels2Percent(props.pos[1], 'y', props.canvas)}%;
    transform: translate(-50%, -50%);
`;

const DecorationWrapper = styled.div<{pos: [number, number], size: [number, number], canvas: [number, number]}>`
    position: absolute;
    z-index: 2;
    left: ${props => pixels2Percent(props.pos[0], 'x', props.canvas)}%;
    top: ${props => pixels2Percent(props.pos[1], 'y', props.canvas)}%;
    width: ${props => pixels2Percent(props.size[0], 'x', props.canvas)}%;
    height: ${props => pixels2Percent(props.size[1], 'y', props.canvas)}%;
    transform: translate(-50%, -50%);
`;

const DecorationDebugIndex = styled.div`
    position: absolute;
    z-index: 3;
    left: 50%;
    top: 50%;
    width: 30px;
    height: 30px;
    color: #fff;
    background-color: red;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    font-size: 12px;
    font-weight: bold;
`;

export interface MapProps {
    focus?: number;
    user?: IUser;
    project: IProject;
    onBack?: () => void;
    onFocus?: (index: number) => void;
    onSelect?: (index: number, openImmediatly: boolean) => void;
    onScrollEnd?: (index: number) => void;
}

const SCROLL_DURATION = 1500;
const MIN_STEP_SCROLL_DURATION = 500;

export const Map: React.FC<MapProps> = (props) => {
    const { focus, user, project, onBack, onFocus, onSelect, onScrollEnd } = props;
    const { games } = project;
    const { levels, bg, canvas } = project.data.map;
    
    const { t } = useTrans();
    const [step, setStep] = useState<number | undefined>(focus !== undefined ? focus : -1);
    const [speed, setSpeed] = useState<number>(SCROLL_DURATION);
    const booted = useRef(false);

    const moveToNext = useCallback(() => {
        if ((focus === undefined) || (step === undefined)) return;
        const diffStep = focus - step;
        if (diffStep !== 0) {
            const duration = Math.abs(diffStep) > 1 ? 0 : Math.max(MIN_STEP_SCROLL_DURATION, SCROLL_DURATION / Math.abs(diffStep));
            // setStep(step => (diffStep > 0 ? 1 : -1) + step!);
            setStep(step => focus);
            setSpeed(speed => duration);
        }
        if (focus !== undefined && step === focus) {
            if (onScrollEnd) {
                onScrollEnd(focus);
            }
        }
    }, [step, focus]);

    /*useEffect(() => {
        Events.scrollEvent.register('end', moveToNext);
    }, [moveToNext]);*/

    useEffect(() => {
        if (focus !== undefined) {
            moveToNext();
        }
    }, [focus]);

    useEffect(() => {
        if (step !== undefined) {
            const duration = booted.current ? speed : 0;
            scroller.scrollTo(`base-${step}`, {
                duration,
                offset: -300,
                smooth: true,
            });
            booted.current = true;
        } else {
            //scroll.scrollToTop();
        }
    }, [step]);

    const userdata = user && user.stats ? user.stats[project.id] : undefined;
    const progress = userdata?.progress;

    const slices = Math.ceil(games.length / levels.length);
    var deco_index = 0;

    return (
        <Container>
        {[...Array(slices)].map((NaN, slice) => {
            const len = levels.length;
            const from = slice*len;
            const slice_games = games.slice(from, from+len);
            return (<Wrapper key={slice} offset={slice > 0 ? -7 : 0}>
                <Bg>
                    <img src={asset(bg)} width={canvas[0]} height={canvas[1]} />
                </Bg>
                {slice_games.map((game, local_index) => {
                    const index = from + local_index;
                    const level = levels[local_index];
                    const active = (progress !== undefined && (progress >= index)) || (index === 0);
                    return (<Section key={index}>
                        <BaseWrapper id={`base-${index}`} pos={level.coord} canvas={canvas}>
                            <Base
                                active={active}
                                label={`${index+1}`}
                                game={game}
                                onFocus={() => {
                                    if (active) {
                                        if (onFocus) onFocus(index);
                                        if (onSelect) onSelect(index, step === index);
                                    }
                                }}
                            />
                        </BaseWrapper>
                        <Segment
                            active={step !== undefined && step >= index}
                            path={level.segment}
                            speed={speed}
                            canvas={canvas}
                            onCompleteAnimation={moveToNext}
                        />
                        {level.decorations && level.decorations!.map((decoration, index2) => {
                            const is_deco = config.debug && !decoration.image.includes('grass');
                            if (is_deco) deco_index++;
                            return (<DecorationWrapper key={index2} pos={decoration.coord} size={decoration.size} canvas={canvas}>
                                <Decoration
                                    active={focus !== undefined && focus >= index}
                                    image={decoration.image}
                                    text={decoration.text}
                                    tooltip={{
                                        vertical: decoration.coord[1] < 1000 ? 'bottom' : 'top',
                                        // horizontal: decoration.coord[0] < 200 ? 'right' : 'left',
                                    }}
                                    color={decoration.color}
                                />
                                {is_deco && <DecorationDebugIndex>{deco_index}</DecorationDebugIndex>}
                            </DecorationWrapper>);
                        })}
                    </Section>);
                })}
            </Wrapper>);
        })}
        <Footer>
            <Button
                icon={'back'}
                text={t('Map.back_home')}
                variant="secondary"
                onPress={onBack}
            />
        </Footer>
        </Container>
    );
}
