import React, { useEffect } from 'react';
import styled from 'styled-components';
import { IGame } from '../../../types';
import tictactoe_img from './tictactoe.svg';
import quiz_img from './quiz.svg';
import memory_img from './memory.svg';
import find_diff_img from './find_diff.svg';
import water_catch_img from './water_catch.svg';
import story_img from './story.svg';

const StyledBase = styled.div<{active?: boolean}>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 72px;
    border-radius: 100%;
    background-color: ${props => props.active ? '#00B2DA' : '#8BAAB2'};
    font-size: 50px;
    font-weight: bold;
    color: #fff;
    transition: background-color .5s;
`;

const Type = styled.span`
    position: absolute;
    right: 0px;
    bottom: 5px;
    width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 100%;
    pointer-events: none;

    > img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 12px;
    }
`;

export interface BaseProps {
    active?: boolean;
    label: string;
    game: IGame;
    onFocus?: () => void;
}

export const Icons = {
    /*'tictactoe': tictactoe_img,
    'quiz': quiz_img,
    'memory': memory_img,
    'find_diff': find_diff_img,
    'water_catch': water_catch_img,*/
    'story': story_img,
} as Record<string, string>;

export const Base: React.FC<BaseProps> = (props) => {
    const { active, label, game, onFocus } = props;
    return (
        <StyledBase active={active} onClick={onFocus}>
            {label}
            {active && Icons[game.type] && <Type><img src={Icons[game.type]} /></Type>}
        </StyledBase>
    );
};