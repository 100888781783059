import React, { useCallback, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import CountUp from 'react-countup';
import { vh100 } from '../../../app/theme';
import { useSFX } from '../../../app/hooks/useSFX';
import { useTrans, transVal } from '../../../app/services/i18n';
import { IGameSurvey, IGameSurveySecret } from '../../../types';
import { Button, ButtonBar } from '../../Atoms/Button/Button';
import { GameEvents, GameSecret } from '../Common';
import { Styles } from '../Quiz/Quiz';
import { SurveyCompleted } from './SurveyCompleted';

const {
    Wrapper, Footer, BulletBar, Bullet, Text, Controls, Answers, Answer
} = Styles;

export interface SurveyProps extends GameEvents, GameSecret<IGameSurveySecret>, IGameSurvey {}

export const Survey: React.FC<SurveyProps> = (props) => {
    const { secret, onWin, onLoss, questions, results } = props;
    const { scores } = secret || {};

    const { t } = useTrans();
    const sfx = useSFX();
    const [focus, setFocus] = useState<number>();
    const [score, setScore] = useState<number>(0);
    const [scoreBoard, setScoreBoard] = useState<boolean[]>([]);
    const [progress, setProgress] = useState<number>(0);
    const [phase, setPhase] = useState<string>("idle");
    const delay = useRef<number>();

    useEffect(() => {
        if (phase === "completed") {
            onWin({
                score
            });
            return;
        }
    }, [phase, score]);

    const completedSurvey = useCallback(() => {
        setPhase('completed');
    }, []);

    const chooseAnswer = useCallback((index) => {
        if (phase === "idle") {
            setFocus(index);
        }
    }, [phase]);
    
    const proceedToNext = useCallback(() => {
        if (delay.current) {
            clearTimeout(delay.current);
        }
        if (progress >= questions.length-1) {
            setPhase("summary");
            return;   
        }
        const newProgress = progress + 1;
        setProgress(newProgress);
        setFocus(undefined);
        setPhase("idle");
    }, [score, progress, questions]);
    
    const proceedToNextDelayed = useCallback(() => {
        if (delay.current) {
            clearTimeout(delay.current);
        }
        delay.current = window.setTimeout(proceedToNext, 2000);
    }, [score, proceedToNext]);

    const confirmAnswer = useCallback(() => {
        // check answer
        if (focus !== undefined) {
            const this_score = scores[progress][focus];
            setScore(score+this_score);
            setScoreBoard(scoreBoard => ([...scoreBoard, true]))
            setPhase("right");
            sfx.correct();
        }
    }, [score, progress, focus]);

    const question = questions[progress];
    
    if (phase === 'summary') {
        return <SurveyCompleted
            value={score}
            results={results}
            onComplete={completedSurvey}
        />
    }

    return <Wrapper>
        <BulletBar>
            {questions.map((question, index) => (
            <Bullet key={index} active={progress === index} result={scoreBoard[index]} />
            ))}
        </BulletBar>
        <Controls>
            <Text dangerouslySetInnerHTML={{__html: transVal(question.text)}} />
            <Answers>
            {question.answers.map((answer, index) => (
                <Answer
                    key={index}
                    focus={focus === index}
                    right={false}
                    onClick={() => chooseAnswer(index)}
                >{transVal(answer)}</Answer>
            ))}
            </Answers>
            <Footer>
                {phase === "idle" && <Button text={t('Survey.confirm')} onPress={() => {
                    confirmAnswer();
                    proceedToNextDelayed();
                }} disabled={focus === undefined} />}
                {phase !== "idle" && <Button text={t('Survey.confirm')} onPress={proceedToNext} disabled={focus === undefined} />}
            </Footer>
        </Controls>
    </Wrapper>;
}