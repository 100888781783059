import React, { useState } from 'react';
import styled from 'styled-components';
import { config } from '../../../app/services/config';
import { useTrans } from '../../../app/services/i18n';
import { ButtonBar, Button } from '../../Atoms/Button/Button';
import { Modal, Title, Text } from '../../Modal/Modal';

export interface FAQItem {
    question: string;
    answer: string;
}

const StyledFAQ = styled.article<{expanded?: boolean}>`
    font-size: 18px;
    line-height: 1.166;
    padding: 0 10px;
    border-bottom: 1px solid #e3e3e3;

    > header {
        color: ${props => props.expanded ? '#00A19A' : '#706F6F'};
        font-weight: ${props => props.expanded ? '700' : 'normal'};
        padding: 10px 0;
        
        > img {
            float: right;
            margin-left: 10px;
            margin-top: 5px;
        }
    }

    > p {
        padding: 2px 0;
        padding-bottom: 20px;
    }
`;

interface FAQItemProps extends FAQItem{
    expanded?: boolean;
    onClick: () => void;
}

const FAQArticle: React.FC<FAQItemProps> = (props) => {
    const { question, answer, expanded, onClick } = props;

    return (
        <StyledFAQ expanded={expanded} onClick={onClick}>
            <header>
                <img src={require(expanded ? './close.svg' : './plus.svg').default} />
                {question}
            </header>
            {expanded && <p dangerouslySetInnerHTML={{__html: answer}} />}
        </StyledFAQ>
    )
}

const Wrapper = styled.section`
    margin: 28px 0;
    border-top: 1px solid #e3e3e3;
`;

export interface FAQProps {
    items: FAQItem[];
    onBack?: () => void;
}

export const FAQ: React.FC<FAQProps> = (props) => {
    const { items, onBack } = props;

    const { t, h } = useTrans();
    const [focus, setFocus] = useState(-1);

    return (
        <Modal id='FAQ'>
            <Title>{t('FAQ.title')}</Title>
            <Text>
                {h('FAQ.text')}
            </Text>
            <Wrapper>
                {items.map((item, i) => <FAQArticle {...item} onClick={() => setFocus(i === focus ? -1 : i)} expanded={focus === i} />)}
            </Wrapper>
            <ButtonBar>
                <Button variant="primary" text={t('FAQ.read_more')} href={config.website} target="_blank" />
            </ButtonBar>
            <ButtonBar>
                <Button variant="action" text={t('FAQ.back')} onPress={onBack} />
            </ButtonBar>
        </Modal>
    );
}