import React from 'react';
import styled from 'styled-components';

const Field = styled.article`
    margin-bottom: 1em;

    > label {
        display: block;
        font-size: 16px;
        font-weight: 700;
        line-height: 1;
        color: #00A19A;
        margin: 8px;
    }
    > input {
        width: 100%;
        padding: .5em;
        font-size: 15px;
        color: #706F6F;
        background-color: #fff;
        border: 1px solid #00A19A;
        border-radius: 19px;

        -webkit-user-select: text; // @hotfix! Without this Safari don't write into inputs!

        &:-webkit-autofill,
        &:autofill {
            background-color: #fff;
        }
    }
`;

const Error = styled.div`
    padding: .5em;
    font-size: .8em;
    color: #ff0000;
`;

export interface InputProps {
    label: string;
    type?: string;
    value?: string;
    placeholder?: string;
    errors?: string[];
    onChange: (val: string) => void;
}

export const Input: React.FC<InputProps> = ({ label, type, value, placeholder, errors, onChange }) => {
    return (
        <Field>
            <label>{label}</label>
            <input
                type={type || "text"}
                value={value}
                placeholder={placeholder}
                onChange={(e) => onChange(e.target.value)}
            />
            {errors && <Error>{errors.join(' - ')}</Error>}
        </Field>
    );
}