import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Input } from '../../components/Atoms/Input/Input';
import { Button, ButtonBar } from '../../components/Atoms/Button/Button';
import { selectError, dismissError, setNewPassword, dismissSuccess, selectSuccess } from '../../app/features/auth';
import { Title, Modal } from '../../components/Modal/Modal';
import { Alert } from '../../components/Alert';
import { useTrans } from '../../app/services/i18n';
import { session } from '../../app/services/config';

interface ResetPasswordRouteParams {
    email: string;
    token: string;
}

export interface ResetPasswordProps {
}

interface FormState {
    password: string;
    password_confirmation: string;
}

export const ResetPassword: React.FC<ResetPasswordProps> = () => {
    const { t } = useTrans();
    const [form, setForm] = useState<Partial<FormState>>({});
    const history = useHistory();
    const params = useParams<ResetPasswordRouteParams>();
    const success = useSelector(selectSuccess);
    const error = useSelector(selectError);
    const dispatch = useDispatch();

    useEffect(() => {
        session.set('login_introduced', true);
    }, [])

    useEffect(() => {
        if (error) {
            setTimeout(onErrorPurge, 3000);
        }
    }, [error]);

    const isDisabled = useMemo(() => {
        return !(form.password && form.password_confirmation);
    }, [form.password, form.password_confirmation]);

    // input change
    const onChange = useCallback((key: string, value: string) => {
        setForm({...form, [key]: value});
    }, [form]);

    const onSubmit = useCallback(() => {
        if (!isDisabled)
            dispatch(setNewPassword({
                email: params.email,
                token: params.token,
                password: form.password!,
                password_confirmation: form.password_confirmation!,
            }));
    }, [form, isDisabled]);

    const onSuccessPurge = useCallback(() => {
        dispatch(dismissSuccess());
        history.push(`/`);
    }, []);

    const onErrorPurge = useCallback(() => {
        dispatch(dismissError());
    }, []);

    const inputError = useCallback((name) => {
        if (!error || !error.data) return;
        const err = error.data![name];
        if (err && err.length) return err;
        return;
    }, [error]);

    return (
        <>
            {success && <Alert delayClose={3000} onClose={onSuccessPurge}>{t('ResetPassword.success')}</Alert>}
            {error && !error.data && <Alert onClose={onErrorPurge}>{t(error.error!)}</Alert>}
            <Modal id='ResetPassword'>
                <Title>{t('ResetPassword.title')}</Title>
                <Input
                    label={t('ResetPassword.password')}
                    type={"password"}
                    onChange={(val) => onChange('password', val)}
                    errors={inputError('password')}
                />
                <Input
                    label={t('ResetPassword.password_confirmation')}
                    type={"password"}
                    onChange={(val) => onChange('password_confirmation', val)}
                    errors={inputError('password_confirmation')}
                />
                <ButtonBar>
                    <Button
                        text={t('ResetPassword.continue')}
                        onPress={() => onSubmit()}
                        disabled={isDisabled}
                    />
                </ButtonBar>
            </Modal>
        </>
    );
}
