import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { config } from '../../app/services/config';
import { changeLang, getCurrentLang, getLangs, useTrans } from '../../app/services/i18n';
import { ButtonBar, Button } from '../Atoms/Button/Button';
import { Divider, Modal } from '../Modal/Modal';
import { FAQ, FAQItem } from './FAQ/FAQ';
import { Selector } from '../Languages/Selector';

const Logo = styled.div`
    max-width: 170px;
    margin: 40px auto;

    > img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
    }
`;

type SECTIONS = 'faq' | 'privacy';

export interface HelpProps {
    onBack?: () => void;
    onLogout?: () => void;
}

export const Help: React.FC<HelpProps> = (props) => {
    const { onBack, onLogout } = props;

    const { t } = useTrans();
    const [section, setSection] = useState<SECTIONS>();
    const history = useHistory();

    const items = [] as FAQItem[];
    for (let i=0; i<100; i++) {
        const key = `FAQ.a${i}`;
        const answer = t(key, {website: config.website});
        const question = t(`FAQ.q${i}`);
        if (answer === key) continue;

        items.push({
            answer,
            question
        });
    }
    
    return (
        <Modal id='Help'>
            {!section && <>
                <Logo>
                    <img src={require('../Splash/wami-full.svg').default} />
                </Logo>
                <ButtonBar>
                    <Button variant="action" text={t('Help.back_home')} onPress={() => { history.push(`/projects`); if (onBack) onBack(); }} />
                </ButtonBar>
                <ButtonBar>
                    <Button variant="light" text={t('Help.menu_faq')} onPress={() => setSection('faq')} />
                </ButtonBar>
                <ButtonBar>
                    <Button variant="primary" text={t('Help.menu_privacy')} href={t('Help.privacy_url')} target="_blank" />
                </ButtonBar>
                <Divider />
                <Selector
                    variant='action'
                    selected={getCurrentLang()}
                    languages={getLangs()}
                    onPress={(lang) => changeLang(lang)}
                />
                <ButtonBar>
                    <Button variant="link" text={t('Help.menu_logout')} icon={require('./logout.svg').default} onPress={onLogout} />
                </ButtonBar>
            </>}
            {section === 'faq' && <>
                <FAQ items={items} onBack={() => setSection(undefined)} />
            </>}
        </Modal>
    );
}