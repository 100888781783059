import React, { useCallback, useEffect, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  html {
    height: -webkit-fill-available;
    color: #706F6F;
    overscroll-behavior: none;
  }

  * {
    font-family: "brandon-grotesque", sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;

    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;

    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none;
  }

  /*html, body {
    height: 100%;
  }*/

  .freezed {
    overflow: hidden;
    touch-action: none;
  }
`;

export function vh100(mode = 'min') {
    // taken from https://github.com/mvasin/react-div-100vh
    const viewportHeight = document.documentElement?.clientHeight || window.innerHeight;
    const cssProp = mode === 'min' ? 'min-height' : 'max-height';
    return `
      /* height: 100%; */
      /* ${cssProp}: 100vh; */
      /* ${cssProp}: -webkit-fill-available; */
      /* ${cssProp}: calc(100vh - env(safe-area-inset-bottom)); */
      /* ${cssProp}: ${viewportHeight}px; */

      ${cssProp}: var(--viewport-safe-height, 100vh);
  `;
}

export function cssVars(obj: any) {
    return Object.keys(obj).reduce((acc, key) => ({ ...acc, [`--${key}`]: obj[key] }), {});
}

export function hexToCSSFilters(hex: string) {
    // really thanks to https://codepen.io/sosuke/pen/Pjoqqp
    switch (hex) {
        case '#3aa8a3': return 'filter: invert(62%) sepia(11%) saturate(2030%) hue-rotate(128deg) brightness(90%) contrast(90%);';
        case '#0098da': return 'filter: invert(41%) sepia(84%) saturate(3103%) hue-rotate(173deg) brightness(99%) contrast(101%);';
        case '#919593': return 'filter: invert(65%) sepia(6%) saturate(118%) hue-rotate(98deg) brightness(89%) contrast(88%);';
        case '#ffffff': return 'filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(226deg) brightness(101%) contrast(102%);';
    }
    return '';
}


/***
 * ViewportResolver tries to resolve 100vh problem in iOS.
 * It initialize a "--viewport-safe-height" CSS var
 */
const ViewportCalcLayer = styled.div`
    position: fixed;
    visibility: hidden;
    pointer-events: none;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

interface ViewportResolverProps {
    children: any;
}

export const ViewportResolver: React.FC<ViewportResolverProps> = (props) => {
    const node = useRef<HTMLElement>();
    const { children } = props;

    const calc = useCallback(() => {
        if (node.current) {
            const vh100 = node.current.clientHeight;
            document.documentElement.style.setProperty('--viewport-safe-height', `${vh100}px`);
        }
    }, [node.current]);

    useEffect(() => {
        window.addEventListener('resize', function() {
            calc();
        });
    }, [calc]);

    return (<>
        <ViewportCalcLayer ref={(el) => { if (el) node.current = el; calc();  }} />
        {children}
    </>);
}
