import React from 'react';
import styled from 'styled-components';
import { useTrans } from '../../../app/services/i18n';
import { IProject, IUserStats } from '../../../types';
import { Modal, Title, Text } from '../../Modal/Modal';
import { Stats } from '../Stats/Stats';

const Wrapper = styled.div`
    margin: 0 20px;
    margin-top: 40px;
    margin-bottom: 12px;

    > article {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;

        > figure {
            flex: 0 0 64px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 62px;
            height: 62px;
        }

        > div {
            flex: 0 0 50%;
            font-size: 18px;
            color: #00A19A;
        }
    }
`;

const WrapStats = styled.div`
    max-width: 250px;
    margin: 0 auto;
`;

const StyledItem = styled.div<{active?: boolean}>`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 23px;
    color: #939598;
    margin-bottom: 10px;

    > figure {
        flex: 0 0 62px;
        padding-right: 10px;

        > img {
            display: block;
            width: 100%;
            margin: 0 auto;
        }
    }

    > div {
        > span {
            font-size: 30px;
            font-weight: 700;
            color: #00A19A;
        }
    }
`;

const Progress = styled.div`
    font-size: 18px;

    > strong {
        display: inline-block;
        font-weight: bold;
        color: #00A19A;
        margin-right: .5em;
    }
`;

export interface LevelsProps {
    projects: IProject[];
    stats?: IUserStats;
    onBack?: () => void;
}

export const Levels: React.FC<LevelsProps> = (props) => {
    const { projects, stats, onBack } = props;
    
    const { t } = useTrans();
    
    const played_projects = stats?.overall.played_projects || 0;
    const played_levels = stats?.overall.played_levels || 0;

    return (
        <Modal id='Levels'>
            <Title>{t('Levels.title')}</Title>
            <Text size='big'>{t('Levels.text')}</Text>

            <WrapStats>
                <Stats items={[
                    { icon: 'route', value: `${played_projects}/${projects.length}`, label: t('Levels.stats_projects') },
                    { icon: 'backpack', value: `${played_levels}`, label: t('Levels.stats_levels') },
                ]} />
            </WrapStats>

            <Wrapper>
                {projects.map( (item, index) => {
                    if (!stats || !stats[item.id] || !stats[item.id].progress) return null;

                    const userdata = stats[item.id];
                    return (
                        <StyledItem key={index}>
                            {item.country && (
                            <figure>
                                <img src={require(`./areas/${item.country.data.continent}.svg`).default} alt={item.country.data.continent} />
                            </figure>
                            )}
                            <div>
                                <span>{item.name}</span>
                                
                                {userdata && userdata.progress && <Progress><strong>{userdata?.progress}/{item.levels}</strong> {t('Levels.stats_project_levels')}</Progress>}
                            </div>
                        </StyledItem>
                    );
                })}
            </Wrapper>
        </Modal>
    );
}