import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { IUser } from '../../types';
import { config } from '../../app/services/config';
import { Input } from '../../components/Atoms/Input/Input';
import { Button, LinkButton, ButtonBar } from '../../components/Atoms/Button/Button';
import { sendResetPasswordMail, selectSuccess, selectError, dismissError, dismissSuccess } from '../../app/features/auth';
import { Title, Text, Divider, Modal, IntroText } from '../../components/Modal/Modal';
import { Alert } from '../../components/Alert';
import { MODES } from './Auth';
import { useTrans } from '../../app/services/i18n';

export interface ForgetPasswordProps {
    onChangeMode: (mode: MODES) => void;
}

interface FormState {
    email: string;
}

export const ForgetPassword: React.FC<ForgetPasswordProps> = ({onChangeMode}) => {
    const { t } = useTrans();
    const [form, setForm] = useState<Partial<FormState>>({});
    const success = useSelector(selectSuccess);
    const error = useSelector(selectError);
    const dispatch = useDispatch();

    // input change
    const onChange = useCallback((key: string, value: string) => {
        setForm({...form, [key]: value});
    }, [form]);

    // classic login
    const onSubmit = useCallback(() => {
        dispatch(sendResetPasswordMail(form.email!));
    }, [form]);

    const onSuccessPurge = useCallback(() => {
        dispatch(dismissSuccess());
        onChangeMode('login');
    }, []);

    const onErrorPurge = useCallback(() => {
        dispatch(dismissError());
    }, []);

    const inputError = useCallback((name) => {
        console.log(error)
        if (!error || !error.data) return;
        const err = error.data![name];
        if (err && err.length) return err;
        return;
    }, [error]);

    return (
        <>
            {success && <Alert delayClose={3000} onClose={onSuccessPurge}>{t('ForgetPassword.success')}</Alert>}
            {error && !error.data && <Alert onClose={onErrorPurge}>{t(error.error!)}</Alert>}
            <Modal id='ForgetPassword'>
                <Title>{t('ForgetPassword.title')}</Title>
                <Text>
                {t('ForgetPassword.text')}
                </Text>
                <Input
                    label={"email"}
                    onChange={(val) => onChange('email', val)}
                    errors={inputError('email')}
                />
                <ButtonBar>
                    <Button
                        text={t('ForgetPassword.submit')}
                        onPress={() => onSubmit()}
                    />
                </ButtonBar>
                <ButtonBar>
                    <Button
                        text={t('ForgetPassword.back')}
                        icon='back'
                        variant="action"
                        onPress={() => onChangeMode('login')}
                    />
                </ButtonBar>
            </Modal>
        </>
    );
}
