import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useTrans } from '../../../app/services/i18n';
import { useSFX } from '../../../app/hooks/useSFX';
import { renderHTML } from '../../helpers';
import { Text, Modal } from '../../Modal/Modal';
import { IProject } from '../../../types';
import { Button, ButtonBar } from '../../Atoms/Button/Button';

const IntroPicture = styled.figure`
    max-width: 240px;
    margin: 0 auto;
    margin-bottom: 20px;
    text-align: center;

    > img {
        max-width: 100%;
        max-height: 200px;
    }
`;

const StyledButtonBar = styled.div`
    text-align: center;

    > button {
        display: inline-flex;
    }
`;

export interface IntroProps {
    project: IProject;
    image: string;
    text: string;
    onClose: () => void;
}

export const Intro: React.FC<IntroProps> = ({ image, text, onClose }) => {
    const { t } = useTrans();
    
    return (
        <Modal id='Intro'>
            <IntroPicture>
                <img src={image} />
            </IntroPicture>
            <Text>
                {renderHTML(text)}
            </Text>
            <StyledButtonBar>
                <Button
                    text={t('Projects.intro_button')}
                    onPress={() => onClose()}
                />
            </StyledButtonBar>
        </Modal>
    )
};