import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    & {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    & div {
        position: absolute;
        border: 4px solid #00A19A;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    & div:nth-child(2) {
        animation-delay: -0.5s;
    }
    @keyframes lds-ripple {
        0% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: 0px;
            left: 0px;
            width: 72px;
            height: 72px;
            opacity: 0;
        }
    }
`;

const Centered = styled.div`
    position: fixed;
    z-index: 14;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const LocalCentered = styled.div`
    position: absolute;
    z-index: 14;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

export const Spinner: React.FC = () => {
    return (
        <Wrapper>
            <div></div><div></div>
        </Wrapper>
    );
}

export const CenteredSpinner: React.FC = () => {
    return (
        <Centered>
            <Spinner />
        </Centered>
    );
}

export const LocalCenteredSpinner: React.FC = () => {
    return (
        <LocalCentered>
            <Spinner />
        </LocalCentered>
    );
}