import React from 'react';
import { ButtonBar, Button, ButtonProps } from '../Atoms/Button/Button';
import { useTrans } from '../../app/services/i18n';

export interface SelectorProps extends Omit<ButtonProps, 'onPress'> {
    selected: string;
    languages: string[];
    onPress: (lang: string) => void;
}

export const Selector: React.FC<SelectorProps> = (props) => {
    const { variant, selected, languages, onPress } = props;
    
    const { t } = useTrans();
    
    return (
        <>
            {languages.map((lang) => {
                if (lang === selected) return null;
                return <ButtonBar key={lang}>
                    <Button variant={variant || 'primary'} text={t(`Help.change_language_${lang}`)} onPress={() => onPress(lang)} />
                </ButtonBar>
            })}
        </>
    );
}
