import React, { useCallback, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSFX } from '../../../app/hooks/useSFX';
import { useTrans, transVal } from '../../../app/services/i18n';
import { IGameQuiz, IGameQuizSecret } from '../../../types';
import { Button, ButtonBar } from '../../Atoms/Button/Button';
import { GameEvents, GameSecret } from '../Common';

const Table = styled.div`
    display: flex;
    color: #00AFAA;
    text-align: center;
    margin-bottom: 1em;

    > * {
        flex: 1;
        
        > label {
            display: block;
            font-size: 18px;
        }
        > span {
            font-size: 32px;
        }
    }
`;

const Wrapper = styled.div``;

const Footer = styled.footer`
    text-align: right;

    > button {
        color: #fff;
        background-color: #706F6F;

        &:disabled {
            background-color: rgba(112,111,111,.7);
        }
    }
`;

const BulletBar = styled.nav`
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    margin-bottom: 35px;

    > * {}
`;

const Bullet = styled.div<{active: boolean, result?: boolean}>`
    width: 20px;
    height: 20px;
    border: 1px solid #97D1F0;
    border-radius: 100%;
    border-color: ${props => props.active ? '#00A19A' : '#707070'};
    ${props => props.result !== undefined ? `
        background-image: url(${require(props.result ? './right.svg' : './wrong.svg').default});
        background-size: 70% auto;
        background-position: center;
        background-repeat: no-repeat;
        background-color: ${props.result ? '#00A19A' : '#707070'};
        border-color: ${props.result ? '#00A19A' : '#707070'};
    ` : null }
`;

const Text = styled.div`
    font-size: 22px;
    line-height: 1.16;
    padding: 0 20px;
    text-align: center;
    height: 76px;
    overflow: auto;
`;

const Controls = styled.section`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
`;

const Answers = styled.div`
    margin-top: 30px;
    margin-bottom: 48px;
`;

const Answer = styled.div<{focus: boolean, right: boolean}>`
    display: flex;
    height: 58px;
    margin-bottom: 8px;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    line-height: 1.13;
    color: ${props => props.focus || props.right ? '#fff' : '#00A19A'};
    background-color: ${props => props.right ? '#707070' : (props.focus ? '#00A19A' : '#DEEEE9')};
    border-radius: 34px;
`;

export const Styles = {
    Wrapper, Footer, BulletBar, Bullet, Text, Controls, Answers, Answer
};

const MIN_MATCHES_TO_WIN = 2;

export interface QuizProps extends GameEvents, GameSecret<IGameQuizSecret>, IGameQuiz {}

export const Quiz: React.FC<QuizProps> = (props) => {
    const { secret, onWin, onLoss, questions } = props;
    const { correct } = secret || {};

    const { t } = useTrans();
    const sfx = useSFX();
    const [focus, setFocus] = useState<number>();
    const [score, setScore] = useState<number>(0);
    const [scoreBoard, setScoreBoard] = useState<boolean[]>([]);
    const [progress, setProgress] = useState<number>(0);
    const [phase, setPhase] = useState<string>("idle");
    const delay = useRef<number>();

    useEffect(() => {
        if (phase === "completed") {
            if (score < MIN_MATCHES_TO_WIN) {
                onLoss();
            } else {
                onWin();
            }
            return;
        }
    }, [phase, score]);

    const chooseAnswer = useCallback((index) => {
        if (phase === "idle") {
            setFocus(index);
        }
    }, [phase]);
    
    const proceedToNext = useCallback(() => {
        if (delay.current) {
            clearTimeout(delay.current);
        }
        if (progress >= questions.length-1) {
            setPhase("completed");
            return;   
        }
        const newProgress = progress + 1;
        setProgress(newProgress);
        setFocus(undefined);
        setPhase("idle");
    }, [score, progress, questions]);
    
    const proceedToNextDelayed = useCallback(() => {
        if (delay.current) {
            clearTimeout(delay.current);
        }
        delay.current = window.setTimeout(proceedToNext, 2000);
    }, [score, proceedToNext]);

    const confirmAnswer = useCallback(() => {
        // check answer
        if (correct[progress] === focus) {
            setScore(score+1);
            setScoreBoard(scoreBoard => ([...scoreBoard, true]))
            setPhase("right");
            sfx.correct();
        } else {
            setScoreBoard(scoreBoard => ([...scoreBoard, false]))
            setPhase("wrong");
            sfx.wrong();
        }
    }, [score, progress, focus]);

    const question = questions[progress];

    return <Wrapper>
        <BulletBar>
            {questions.map((question, index) => (
            <Bullet key={index} active={progress === index} result={scoreBoard[index]} />
            ))}
        </BulletBar>
        <Controls>
            <Text dangerouslySetInnerHTML={{__html: transVal(question.text)}} />
            <Answers>
            {question.answers.map((answer, index) => (
                <Answer
                    key={index}
                    focus={focus === index}
                    right={phase === "wrong" && index === correct[progress]}
                    onClick={() => chooseAnswer(index)}
                >{transVal(answer)}</Answer>
            ))}
            </Answers>
            <Footer>
                {phase === "idle" && <Button text={t('Quiz.confirm')} onPress={() => {
                    confirmAnswer();
                    proceedToNextDelayed();
                }} disabled={focus === undefined} />}
                {phase !== "idle" && <Button text={t('Quiz.confirm')} onPress={proceedToNext} disabled={focus === undefined} />}
            </Footer>
        </Controls>
    </Wrapper>;
}