/***
https://github.com/kybarg/react-qr-scanner#readme
    Due to browser implementations the camera can only be accessed over https or localhost.
    Not compatible with macOS/iOS Safari. Use legacyMode to support these platforms.
    In Firefox a prompt will be shown to the user asking which camera to use, so facingMode will not affect it.
 */

import React, { useRef } from 'react';
// @ts-ignore
import QrReader from 'react-qr-scanner'
import styled from 'styled-components';
import { ButtonBar, Button } from '../Button/Button';
import { useTrans } from '../../../app/services/i18n';

const Style = styled.div`
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
`;

const Footer = styled.footer`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
`;

export interface QRCodePickerProps {
    onChange: (val: string) => void;
    onCancel: () => void;
}

export const QRCodePicker: React.FC<QRCodePickerProps> = ({ onChange, onCancel }) => {
    const { t } = useTrans();
    const $scanned = useRef(false);
    
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const previewStyle = {
        width: vw,
        height: vh,
    }
    const handleScan = (data: string) => {
        if (data && !$scanned.current) {
            $scanned.current = true;
            onChange(data);
        }
    };

    const handleError = (err: any) => {
        console.error(err)
    };
    
    return (
        <Style>
            <QrReader
                delay={100}
                style={previewStyle}
                onError={handleError}
                onScan={handleScan}
            />
            <Footer>
                <ButtonBar>
                    <Button onPress={onCancel}>{t('QRCodePicker.cancel')}</Button>
                </ButtonBar>
            </Footer>
        </Style>
    );
}
