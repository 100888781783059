import React from 'react';
import styled from 'styled-components';

const WrapStats = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
`;

const Data = styled.article`
    flex: 0 0 50%;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    color: #00A19A;
    margin-bottom: 20px;

    > img {
        height: 46px;
    }

    > strong {
        display: block;
        font-size: 18px;
    }

    > span {
        display: block;
        line-height: 1;
    }
`;

export interface StatsProps {
    items: {
        icon: string;
        value: string | number;
        label: string;
    }[];
}

export const Stats: React.FC<StatsProps> = (props) => {
    const { items } = props;

    return (
        <WrapStats>
            {items.map((item, i) => (
            <Data key={i}>
                <img src={require(`./${item.icon}.svg`).default} />
                <strong>{`${item.value}`}</strong>
                <span dangerouslySetInnerHTML={{__html: item.label}} />
            </Data>
            ))}
        </WrapStats>
    );
}