import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { asset } from '../../app/helpers';
import { useTrans, transVal, getCurrentLang } from '../../app/services/i18n';
import { IPeople, IVillage, IProject, IUserStats } from '../../types';
import { Button, ButtonBar } from '../Atoms/Button/Button';
import { Image } from '../Image';
import { Gallery } from '../Modal/Gallery';
import { Media } from '../Modal/Media';
import { Modal, Title, Text } from '../Modal/Modal';
import { Stats } from './Stats/Stats';

const StyleFamily = styled.article`
    display: flex;
    align-items: stretch;
    //align-items: center;
    justify-content: center;
    gap: 12px;

    > figure {
        position: relative;
        flex: 0 0 96px;
        width: 96px;
        height: 96px;
        border-radius: 100%;
        background-color: rgba(0, 152, 218, .2);

        > img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
            border-radius: 100%;
        }
    }

    > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // height: 100%;
        font-size: 14px;

        > strong {
            display: block;
            word-break: break-word;
            font-size: 24px;
            font-weight: 700;
            line-height: 1;
            margin-bottom: 4px;
            color: #00A19A;
            text-transform: uppercase;
        }

        > div {
            flex: 1;
            margin-bottom: 10px;
        }
    }
`;

interface FamilyProps extends IPeople {
    onClick: () => void;
}

export const Family: React.FC<FamilyProps> = (props) => {
    const { images, name, liters, onClick } = props;
    const { t } = useTrans();

    return <StyleFamily onClick={onClick}>
        <figure>
            {images && images.cover && <Image src={asset(`${images.path}${images.cover}`)} />}
        </figure>
        <div>
            <strong>{name}</strong>
            {liters && <div>{t('People.family_liters', {liters})}</div>}
            <span><Button size='small'>{t('People.family_details')}</Button></span>
        </div>
    </StyleFamily>
};

const Wrapper = styled.div`
    margin-top: 40px;

    > *:not(:last-child) {
        margin-bottom: 32px;
    }
`;

const WrapStats = styled.div`
    max-width: 200px;
    margin: 0 auto;
    margin-bottom: 20px;
`;

const Centered = styled.div`
    text-align: center;
`;

const FamilyDetails = styled.div`
    font-size: 22px;
    font-weight: bold;
    line-height: 1.16;
    color: #00A19A;
    text-transform: uppercase;
    margin-top: 18px;
    margin-bottom: 18px;
`;

export interface PeopleDetailsProps {
    details: IPeople;
    village?: IVillage;
    project?: IProject;
    onBack: () => void;
}

export const PeopleDetails: React.FC<PeopleDetailsProps> = ({details, village, project, onBack}) => {
    const { t, h } = useTrans();

    return (
        <Media
            media={() => details.images ? <Gallery images={details.images} /> : undefined}
            share={t('People.share_url', { people_id: details.id, lang: getCurrentLang()} )}
        >
            <Centered>
                <Title>{details.name}</Title>
                <FamilyDetails>
                    {h('People.people_data', {
                        project: project?.name || "",
                        village: village?.name || "",
                    })}
                </FamilyDetails>
                {details.text && <Text>{transVal(details.text)}</Text>}
            </Centered>
            <ButtonBar>
                <Button
                    text={t('People.back')}
                    variant="action"
                    icon='back'
                    onPress={onBack}
                />
            </ButtonBar>
        </Media>
    )
}

export interface PeopleProps {
    stats?: IUserStats;
    items: IPeople[];
    villages: IVillage[];
    projects: IProject[];
    onBack?: () => void;
}

export const People: React.FC<PeopleProps> = (props) => {
    const { stats, items, villages, projects, onBack } = props;

    const { t, h } = useTrans();
    const [details, setDetails] = useState<IPeople>();

    const village = useMemo(() => villages.find(v => details && v.id === details.village_id), [villages, details]);
    const project = useMemo(() => projects.find(p => village && p.id === village.project_id), [projects, village]);

    if (details) {
        return <PeopleDetails
            village={village}
            project={project}
            details={details}
            onBack={() => setDetails(undefined)}
        />
    }

    return (
        <Modal id='People'>
            <Title>{t('People.title')}</Title>
            
            <WrapStats>
                <Stats items={[
                    { icon: 'drop', value: stats?.overall.liters || '-', label: t('People.stats_liters') },
                    { icon: 'bottle', value: stats?.codes_count || '-', label: t('People.stats_codes') },
                    { icon: 'tap', value: items.length || '-', label: t('People.stats_people') },
                    { icon: 'village', value: stats?.villages.length || '-', label: t('People.stats_villages') },
                ]} />
            </WrapStats>

            <Text size='big'>
                {h('People.text')}
            </Text>
            <Wrapper>
                {items.map( (family, index) => <Family
                    key={index}
                    {...family}
                    onClick={() => setDetails(family)}
                />)}
            </Wrapper>
        </Modal>
    );
}