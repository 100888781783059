import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { t } from '../../app/services/i18n';
import { Button } from '../Atoms/Button/Button';
import { World } from './World';
import { IProject, IProjectUserData, IUser } from '../../types';

const Group = styled.section`
    &:not(:first-of-type) {
        &:before {
            content: '';
            display: block;
            width: 46px;
            height: 3px;
            margin: 12px 0;
            background-color: #00A19A;
        }
    }
`;

const StyledItem = styled.div<{active?: boolean, activeColor: string}>`
    --item-color: ${props => props.active ? props.activeColor : '#939598'}; // '#0098da' 

    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 26px;
    font-weight: 700;
    color: var(--item-color);
    text-transform: uppercase;

    > figure {
        flex: 0 0 34px;

        > img {
            display: block;
            height: 28px;
            margin: 0 auto;
        }
    }
`;

const Coin = styled.span`
    flex: 0 0 26px;
    width: 26px;
    height: 26px;
    line-height: 26px;
    background-color: var(--item-color);
    color: #fff;
    font-size: 15px;
    text-align: center;
    border-radius: 100%;
    margin-right: 10px;
`;

const Progress = styled.span`
    font-weight: 400;

    @media only screen and (min-width: 321px) {
        margin-left: 1em;
    }
`;

export interface ItemProps {
    item: IProject;
    userdata?: IProjectUserData;
    active?: boolean;
    onClick: () => void;
}

const Item: React.FC<ItemProps> = (props) => {
    const { item, userdata, active, onClick } = props;
    return (
        <StyledItem active={active} activeColor={item.part_of.type === 'partner' ? '#3da05e' : '#0098da'} onClick={onClick}>
            <Coin>{userdata && userdata.coins_free ? userdata.coins_free : '0'}</Coin>
            <span>
                {item.name}
            </span>
            {userdata && userdata.progress && <Progress>{userdata?.progress}/{item.levels}</Progress>}
        </StyledItem>
    );
}

const Wrapper = styled.div`
`;

const Side = styled.aside`
    position: absolute;
    left: 24px;
    right: 24px;
    top: 120px;
    bottom: 20px;
    z-index: 2;

    nav {
        position: absolute;
        bottom: 0;
        right: 0;
    }
`;

const Bg = styled.div`
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
`;

export interface SelectScreenProps {
    user?: IUser;
    projects: IProject[];
    onStart: (project: IProject) => void;
}

export const SelectScreen: React.FC<SelectScreenProps> = (props) => {
    const [focus, setFocus] = useState<IProject>();
    const { user, projects, onStart } = props;

    const pos = focus && focus.country && focus.country.data && focus.country.data.pos ? focus.country.data.pos : undefined;

    const groups = useMemo(() => {
        return projects.reduce<Record<string, IProject[]>>((acc, project) => {
            const type = project.part_of.type;
            if (!acc[type]) acc[type] = [];
            acc[type].push(project);
            return acc;
        }, {});
    }, [projects]);
    const ordering_groups = ["country", "partner", "merchant"];
    
    return (
        <Wrapper>
            <Side>
                {ordering_groups.map((group_name, index) => {
                    const projects = groups[group_name];
                    if (projects) {
                        return <Group key={index}>
                            {projects.map(project => (
                                <Item
                                    key={project.id}
                                    active={project === focus}
                                    item={project}
                                    userdata={user && user.stats ? user.stats[project.id] : undefined}
                                    onClick={() => setFocus(project)}
                                />
                            ))}
                        </Group>;
                    }
                    return null;
                })}
                {focus && (
                <nav>
                    <Button
                        text={t(`SelectScreen.confirm`, { project: focus.id } )}
                        onPress={() => { if (focus) onStart(focus); }}
                    />
                </nav>
                )}
            </Side>
            <Bg>
                <World x={pos?.x} y={pos?.y} />
            </Bg>
        </Wrapper>
    );
}
