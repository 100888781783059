import { config } from './config';
import { http } from './http';

const CONSTANTS = {
    localStorageKey: 'code'
}

export async function insertCode<T>(code: string): Promise<T> {
    try {
        const res = await http.request({
            url: `${config.api}/api/codes/insert`,
            data: {
                code
            },
            withToken: true
        });
        return res.data;
    } catch(err) {
        throw err;
    }
}

export function pinCode(code: string) {
    localStorage.setItem(CONSTANTS.localStorageKey, code);
}

export function getPinnedCode() {
    return localStorage.getItem(CONSTANTS.localStorageKey);
}

export function clearPinnedCode() {
    return localStorage.removeItem(CONSTANTS.localStorageKey);
}
