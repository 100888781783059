import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { logIn, register, selectAuthenticated, selectAuthenticating, socialLogin } from '../../app/features/auth';

import { Login } from './Login';
import { Register } from './Register';
import { ForgetPassword } from './ForgetPassword';
import { pageView } from '../../app/services/tracking';

export type MODES = 'login' | 'register' | 'forget_password';

export const Auth: React.FC = () => {
    const location = useLocation();
    const auth = useSelector(selectAuthenticated);
    const loading = useSelector(selectAuthenticating);
    const dispatch = useDispatch();
    const [mode, setMode] = useState<MODES>('login');

    useEffect(() => {
        if (mode) {
            pageView(mode);
        }
    }, [mode]);

    // prevent to show auth in reset password page (ugly)
    if (location.pathname.indexOf('reset_password') !== -1) {
        return null;
    }
    
    if (auth || loading) {
        // immediatly exit, if logged
        return null;
    }

    if (mode === 'login') return <Login onChangeMode={(mode) => setMode(mode)} />
    if (mode === 'register') return <Register onChangeMode={(mode) => setMode(mode)} />
    if (mode === 'forget_password') return <ForgetPassword onChangeMode={(mode) => setMode(mode)} />

    return null;
}