import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTrans } from '../../../app/services/i18n';
import { generateItems } from '../../../app/helpers';
import { useSFX } from '../../../app/hooks/useSFX';
import { GameEvents } from '../Common';
import x_img from './x.svg';
import o_img from './o.svg';

const Table = styled.div`
    display: flex;
    color: #00AFAA;
    text-align: center;
    margin-bottom: 1em;

    > * {
        flex: 1;
    }
`;

const Cell = styled.div<{active: boolean; opponent?: boolean;}>`
    font-weight: 700;
    color: ${props => props.opponent ? '#0098DA' : '#00AFAA'};
    border-top: 3px solid transparent;
    border-color: ${props => props.active ? '#00AFAA' : 'transparent'};
    
    > label {
        display: block;
        font-size: 18px;
    }
    > span {
        font-size: 32px;
    }
`;

const Grid = styled.div`
    background-color: #deeee9;
`;

const GridRow = styled.div`
    display: flex;
`;

const GridCell = styled.div`
    position: relative;
    flex: 1;
    border: 1px solid #eef7f9;

    &#c1, &#c2, &#c3 {
        border-top: none;
    }

    &&#c3, &#c6, &#c9 {
        border-right: none;
    }

    &#c7, &#c8, &#c9 {
        border-bottom: none;
    }

    &#c1, &#c4, &#c7 {
        border-left: none;
    }
`;

const GridInner = styled.div<{winning: boolean}>`
    position: relative;

    &:before {
        content: "";
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
        padding-top: 100%;
    }

    &:after { /* to clear float */
        content: "";
        display: table;
        clear: both;
    }

    img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    ${props => props.winning ? `
        animation: blink 1s step-start infinite;
    ` : null}
    
    @keyframes blink {
        50% {
            opacity: 0;
        }
    }
`;

const X = () => <img src={x_img} />
const O = () => <img src={o_img} />

export interface TicTacToeProps extends GameEvents {
}

type playertype = "PLAYER" | "CPU";

interface Score {
    [key: string]: number;
}

const MIN_MATCHES_TO_WIN = 2;

export const TicTacToe: React.FC<TicTacToeProps> = (props) => {
    const { onWin, onLoss } = props;

    const { t } = useTrans();
    const sfx = useSFX();
    const [score, setScore] = useState<Score>({ PLAYER: 0, CPU: 0 });
    const [board, setBoard] = useState<playertype[]>([]);
    const [thinking, setThinking] = useState(false);
    const [winner, setWinner] = useState<playertype>();
    const [currentWin, setCurrentWin] = useState<number[]>([]);

    const winningConditions = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6]
    ];

    useEffect(() => {
        if (!winner) return;
        if (winner === "PLAYER") {
            onWin();
        } else {
            onLoss();
        }
    }, [winner, onWin, onLoss]);

    const resetBoard = useCallback(() => {
        setBoard([]);
        setCurrentWin([]);
    }, []);

    const isATie = useCallback((board) => {
        for (let i=0; i<9; i++) {
            if (!board[i]) {
                return false;
            }
        }
        return true;
    }, []);

    const someoneWon = useCallback((board) => {
        const res = winningConditions.reduce<[playertype, number[]] | null>((acc, cond) => {
            const types = ["PLAYER", "CPU"];
            for (let i=0; i<types.length; i++) {
                const type = types[i] as playertype;
                if ((board[cond[0]] === type) && (board[cond[1]] === type) && (board[cond[2]] === type)) {
                    return [type, cond];
                }
            }
            return acc;
        }, null);
        if (res) {
            const [winner, cond] = res;
            const newScore = {...score, [winner]: score[winner]+1};
            setCurrentWin(cond);
            setScore(newScore);
            window.setTimeout(() => {
                resetBoard();
                sfx[winner === "PLAYER" ? 'correct' : 'wrong']();
                if (newScore[winner] === MIN_MATCHES_TO_WIN) {
                    setWinner(winner);
                }
            }, 2000);
            return winner;
        }
        return null;
    }, [score]);

    const cpuMove = useCallback(() => {
        setBoard(board => {
            const freeCell = [];
            for (let i=0; i<9; i++) {
                if (!board[i]) {
                    freeCell.push(i);
                }
            }

            /*** AAI: artigianal artificial intelligence :D */
            const calc = [] as any;
            for (let lineIndex=0; lineIndex<winningConditions.length-1; lineIndex++) {
                const line = winningConditions[lineIndex];
                calc[lineIndex] = { "PLAYER": 0, "CPU": 0 };
                
                for (let j=0; j<line.length; j++) {
                    const k = line[j];
                    calc[lineIndex][board[k]]++;
                }
            }
            let priority = [] as any;
            for (let lineIndex=0; lineIndex<calc.length; lineIndex++) {
                const line = winningConditions[lineIndex];
                for (let j=0; j<line.length; j++) {
                    const cellIndex = line[j];
                    // if free
                    if (!board[cellIndex]) {
                        let cycles = 1;
                        // ATTACK
                        if (calc[lineIndex].CPU === 2) {
                            // I'm going to win!
                            cycles = 100;
                        } else {
                            // DEFENCE
                            // if cpu played on this line, bypass
                            if (calc[lineIndex].CPU === 0) {
                                // high risk!
                                if (calc[lineIndex].PLAYER === 2) cycles = 500;
                                // low risk
                                if (calc[lineIndex].PLAYER === 1) cycles = 10;
                            }
                        }
                        priority = [...priority, ...Array(cycles).fill(cellIndex)];
                    }
                }
            }
            /******/

            // const index = freeCell[Math.ceil(Math.random() * freeCell.length) - 1];
            const index = priority[Math.ceil(Math.random() * priority.length) - 1];
            let newBoard = [...board];
            newBoard[index] = "CPU";
            if (isATie(newBoard)) {
                window.setTimeout(() => {
                    resetBoard();
                }, 2000);
            }
            if (!someoneWon(newBoard)) {
                // nothing to call automatically
            }
            return newBoard;
        });
    }, [score]);

    const playerMove = useCallback((index: number) => {
        if (thinking) {
            return;
        }
        if (!board[index]) {
            let newBoard = [...board];
            newBoard[index] = "PLAYER";
            setBoard(newBoard);
            if (isATie(newBoard)) {
                window.setTimeout(() => {
                    resetBoard();
                }, 2000);
            }
            if (!someoneWon(newBoard)) {
                setThinking(true);
                window.setTimeout(() => {
                    setThinking(false);
                    cpuMove();
                }, ((Math.random() * 2)+1)*1000);
            }
        }
    }, [score, thinking, board, cpuMove, someoneWon]);

    return (<>
        <Table>
            <Cell active={!thinking}>
                <label>{t('TicTacToe.score')}</label>
                <span>{score.PLAYER}</span>
            </Cell>
            <Cell active={thinking} opponent>
                <label>{t('TicTacToe.opponent')}</label>
                <span>{score.CPU}</span>
            </Cell>
        </Table>
        <Grid>
            {generateItems(3).map(row => 
            <GridRow key={row}>
                {generateItems(3).map(col => {
                    const index = row*3+col;
                    return (
                        <GridCell key={index} id={`c${index+1}`} onClick={() => playerMove(index)}>
                            <GridInner winning={currentWin.indexOf(index) !== -1}>
                                {board[index] === "PLAYER" ? <X /> : (board[index] === "CPU" ? <O /> : null)}
                            </GridInner>
                        </GridCell>
                    );
                })}
            </GridRow>
            )}
        </Grid>
    </>);
}
