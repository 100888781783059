import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { loading as appLoading, failure as appFailure } from './app';
import * as gamesService from '../services/games';
import { HttpError, IError, IGame, IMatch, IMatchData } from '../../types';
import { gotProjectCompleted } from './projects';

interface GamesState {
    loading: boolean;
    error?: IError;
    game: IGame | null;
    match: IMatch | null;
    matchResult: IMatchData | null;
}

const initialState: GamesState = {
    loading: false,
    game: null,
    match: null,
    matchResult: null,
};

export const gamesReducer = createSlice({
    name: 'games',
    initialState,
    reducers: {
        loading: (state) => {
            state.loading = true;
        },
        gotGame: (state, action: PayloadAction<IGame>) => {
            state.game = action.payload;
            state.loading = false;
        },
        resetGame: (state) => {
            state.game = null;
        },
        gotMatch: (state, action: PayloadAction<IMatch>) => {
            state.match = action.payload;
            try {
                const secret = JSON.parse(window.atob(state.match.secret));
                state.match.secret = secret;
            } catch(e) {}
            
            state.loading = false;
        },
        resetMatch: (state) => {
            state.match = null;
        },
        gotFailure: (state, action: PayloadAction<IError | undefined>) => {
            state.error = action.payload;
            state.loading = false;
        },
        failurePurge: (state) => {
            state.error = undefined;
        },
    },
});

export const { loading, gotGame, resetGame, gotMatch, resetMatch, gotFailure, failurePurge } = gamesReducer.actions;

export const getGame = (game_id: string): AppThunk => dispatch => {
    dispatch(loading());
    dispatch(resetGame());

    gamesService.getGame(game_id)
    .then((data) => {
        dispatch(gotGame(data));
    }, (error: HttpError) => {
        dispatch(gotFailure(error.response?.data));
        dispatch(appFailure(error.response?.data));
    });
}

export const startMatch = (game_id: string): AppThunk => dispatch => {
    dispatch(loading());

    gamesService.startMatch(game_id)
    .then((data) => {
        dispatch(gotMatch(data));
    }, (error: HttpError) => {
        dispatch(gotFailure(error.response?.data));
        dispatch(appFailure(error.response?.data));
    });
}

export const completeMatch = (token: string, matchData: IMatchData): AppThunk => dispatch => {
    dispatch(loading());

    const { success, ...rest } = matchData;

    gamesService.completeMatch(token, success, rest)
    .then((data) => {
        dispatch(resetMatch());
        if (data.project_completed) {
            dispatch(gotProjectCompleted(data.project_completed.code));
        }
    }, (error: HttpError) => {
        dispatch(gotFailure(error.response?.data));
        dispatch(appFailure(error.response?.data));
    });
}

export const selectLoading = (state: RootState): boolean => state.games.loading;
export const selectGame = (state: RootState): IGame | null => state.games.game;
export const selectMatch = (state: RootState): IMatch | null => state.games.match;
export const selectError = (state: RootState): IError | undefined => state.games.error;

export default gamesReducer.reducer