import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectAuthenticated, selectCurrentUser } from '../../app/features/auth';
import { selectProjects, getProjects, resetProject } from '../../app/features/projects';
import { Layer, LayerProps } from '../../components/Layer';
import { SelectScreen } from '../../components/Projects/SelectScreen';
import { CenteredSpinner } from '../../components/Spinner';

export interface ProjectsProps extends LayerProps {
}

export const Projects: React.FC<ProjectsProps> = (props) => {
    const history = useHistory();
    const auth = useSelector(selectAuthenticated);
    const user = useSelector(selectCurrentUser);
    const projects = useSelector(selectProjects);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetProject());
    }, [dispatch]);

    useEffect(() => {
        if (auth) {
            dispatch(getProjects());
        }
    }, [auth, dispatch]);
    
    return (<>
        {(!projects || projects.length === 0) && <CenteredSpinner />}
        <Layer {...props} grow>
            <SelectScreen
                user={user}
                projects={projects}
                onStart={(project) => {
                    history.push(`/projects/${project.id}`);
                }}
            />
        </Layer>
    </>);
}