import React, { useEffect } from 'react';
import styled from 'styled-components';

const Underlay = styled.div`
    position: fixed;
    z-index: 19;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.7);
`;

const Wrapper = styled.div`
    position: fixed;
    z-index: 20;
    left: 50%;
    top: 50%;
    padding: 2em;
    transform: translate(-50%, -50%);
    background-color: #fff;
    box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.75);
`;

export interface AlertProps {
    notClosable?: boolean;
    delayClose?: number;
    onClose?: () => void;
}

export const Alert: React.FC<AlertProps> = (props) => {
    const { children, notClosable, delayClose = 3000, onClose } = props;

    useEffect(() => {
        if (!notClosable) {
            window.setTimeout(() => {
                if (onClose)
                    onClose();
            }, delayClose);
        }
    }, [notClosable, onClose]);

    return (<>
        <Underlay onClick={() => { if (onClose) onClose(); }} />
        <Wrapper>
            {children}
        </Wrapper>
    </>);
}
