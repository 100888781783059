import React from 'react';
import styled from 'styled-components';
import { vh100 } from '../app/theme';

export interface LayerProps {
    z: number;
    grow?: boolean;
}

export const Layer = styled.div<LayerProps>`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    ${props => props.grow ? vh100() : ''}
    z-index: ${props => props.z};
`;