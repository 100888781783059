import React, { useCallback, useEffect, useState } from 'react';
import {
    Router,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { History } from 'history'
import { OnlyGuestRoute, PrivateRoute } from './app/services/route';
import { selectError, dismissError } from './app/features/app';
import { loadUser, selectAuthenticated, logOut } from './app/features/auth';
import { Header } from './domain/Header';
import { Auth } from './domain/Auth/Auth';
import { InsertCode } from './domain/Codes/InsertCode';
import { Projects } from './domain/Projects/List';
import { ProjectDetails } from './domain/Projects/Details';
import { CheckSession } from './domain/Auth/CheckSession';
import { GameLauncher } from './domain/Games/Launcher';
import { YouWin } from './domain/Games/Result/YouWin';
import { YouLose } from './domain/Games/Result/YouLose';
import { Help } from './components/Help/Help';
import { People } from './domain/People';
import { Alert } from './components/Alert';
import { Levels } from './domain/Levels';
import { selectProjectDetails } from './app/features/projects';
import { ResetPassword } from './domain/Auth/ResetPassword';
import { useTrans } from './app/services/i18n';
import { ModalManager } from './components/Modal/Modal';
import { ViewportResolver } from './app/theme';
import { rememberCode } from './app/features/codes';
import { getPinnedCode } from './app/services/codes';
import { ProtectPortrait } from './components/ProtectPortrait';
import { selectGame } from './app/features/games';
import { pageView } from './app/services/tracking';

type MODES = "user:levels" | "user:people" | "code:insert" | "match:win" | "match:loss" | "app:help";

interface Props {
    appHistory: History;
}

const queryString = window.location.search;
const parameters = new URLSearchParams(queryString);
var codeToRemember = parameters.get('code');

function App(props: Props) {
    const { appHistory } = props;
    const { t } = useTrans();
    const [mode, setMode] = useState<MODES | null>();
    const auth = useSelector(selectAuthenticated);
    const project = useSelector(selectProjectDetails);
    const game = useSelector(selectGame);
    const dispatch = useDispatch();
    const error = useSelector(selectError);

    useEffect(() => {
        if (!codeToRemember) {
            codeToRemember = getPinnedCode();
        }
        if (codeToRemember) {
            dispatch(rememberCode(codeToRemember));
            setMode('code:insert');
        }
    }, []);

    useEffect(() => {
        if (mode) {
            pageView(mode);
        }
    }, [mode]);

    const toggleModal = useCallback(($mode) => {
        setMode(($mode === mode) ? undefined : $mode);
    }, [mode]);

    const close = useCallback(() => {
        setMode(null);
    }, []);

    const closeResultAndRefresh = useCallback(() => {
        close();
        dispatch(loadUser());
    }, []);

    const resetError = useCallback(() => {
        dispatch(dismissError());
    }, []);

    const logout = useCallback(() => {
        appHistory.push(`/projects`);
        setMode(null);
        dispatch(logOut());
    }, []);

    return (
        <ViewportResolver>
            <Router history={appHistory}>
                <ModalManager>
                    {/*<ProtectPortrait />*/}
                    <CheckSession z={100} />
                    {auth && (
                        <Header
                            mode={mode as string}
                            onLevels={() => toggleModal('user:levels')}
                            onPeople={() => toggleModal('user:people')}
                            onInsertCode={() => toggleModal('code:insert')}
                            onHelp={() => toggleModal('app:help')}
                        />
                    )}
                    <Auth />
                    {auth && (<>
                    {mode === 'user:levels' && <Levels onBack={close} />}
                    {mode === 'user:people' && <People onBack={close} />}
                    {mode === 'code:insert' && <InsertCode onBack={closeResultAndRefresh} />}
                    {mode === 'match:win' && project && game && <YouWin project={project} game={game} onPlay={closeResultAndRefresh} onClose={closeResultAndRefresh} />}
                    {mode === 'match:loss' && project && game && <YouLose project={project} game={game} onPlay={closeResultAndRefresh} onClose={closeResultAndRefresh} />}
                    {mode === 'app:help' && <Help onBack={close} onLogout={logout} />}
                    </>)}
                    {error && error.error && <Alert onClose={resetError}>{t(error.error)}</Alert>}

                    <Switch>
                        <Route exact path="/projects">
                            <Projects z={1} />
                        </Route>
                        <PrivateRoute exact path="/projects/:project_id">
                            <ProjectDetails z={1} />
                        </PrivateRoute>
                        <PrivateRoute exact path="/projects/:project_id/games/:game_id">
                            <GameLauncher
                                onWin={(args) => { if (args.no_modal) closeResultAndRefresh(); else setMode('match:win'); }}
                                onLoss={() => setMode('match:loss')}
                                z={99}
                            />
                        </PrivateRoute>
                        <OnlyGuestRoute exact path="/reset_password/:email/:token">
                            <ResetPassword />
                        </OnlyGuestRoute>
                        {/*<Redirect to='/projects' />*/}
                    </Switch>
                </ModalManager>
            </Router>
        </ViewportResolver>
    );
}

export default App;
