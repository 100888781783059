import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import CountUp from 'react-countup';
import { i18n_string, SurveyResult } from '../../../types';
import { transVal, useTrans } from '../../../app/services/i18n';
import { vh100 } from '../../../app/theme';
import { Button, ButtonBar } from '../../Atoms/Button/Button';
import bg from './result.png';

const Completed = styled.div`
    position: relative;
    ${vh100()}
`;

const Content = styled.div`
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Bg = styled.div<{level: number}>`
    position: absolute;
    left: -20px;
    top: -20px;
    right: -20px;
    bottom: 0;
    background: url(${bg});
    background-size: cover;
    transform: translateY(${props => 80-props.level}%);
    transition: transform .5s;
`;

const Result = styled.div`
    font-size: 64px;
    color: #00A19A;
    margin-bottom: 20px;
`;

const Text = styled.div`
    max-width: 260px;
    margin: 0 auto;
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 1.16;
    text-align: center;
`;

export interface SurveyCompletedProps {
    value: number;
    results: SurveyResult[];
    onComplete: () => void;
}

export const SurveyCompleted: React.FC<SurveyCompletedProps> = (props) => {
    const { value, results, onComplete } = props;
    const { t } = useTrans();

    const index = useMemo(() => {
        return results.findIndex(result => (value >= result.min && (!result.max || value <= result.max)) );
    }, [results, value]);

    return <Completed>
        <Content>
            <Result>
                <CountUp end={value} duration={3} /> L
            </Result>
            {index !== undefined && <Text dangerouslySetInnerHTML={{__html: transVal(results[index].text)}} />}
            <ButtonBar>
                <Button text={t('Survey.complete')} onPress={onComplete} />
            </ButtonBar>
        </Content>
        <Bg level={100*index/results.length} />
    </Completed>;
}