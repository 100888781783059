import { config } from './config';
import { http } from './http';
import { IProject } from '../../types';

export async function getProjectDetails(project_id: string): Promise<IProject> {
    try {
        const res = await http.request<IProject>({
            url: `${config.api}/api/projects/show`,
            data: {
                project_id
            },
            withToken: true,
            tryCache: true,
        });
        return res.data;
    } catch(err) {
        throw err;
    }
}

export async function getProjects(): Promise<IProject[]> {
    try {
        const res = await http.request<IProject[]>({
            url: `${config.api}/api/projects/index`,
            withToken: true,
            tryCache: true,
        });
        return res.data;
    } catch(err) {
        throw err;
    }
}