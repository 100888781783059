import React from 'react';
import { RWebShare as WebShare } from "react-web-share";
import styled from 'styled-components';
import icon from './share.svg';

const IconView = styled.figure`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    background-color: #E6E6E6;
    border-radius: 100%;
`;

const Icon = styled.img`
    height: 22px;
`;

export interface ShareProps {
    url: string;
    title?: string;
    text?: string;
}

export const Share: React.FC<ShareProps> = (props) => {
  const { url, title, text } = props;

  return (
    <WebShare
        data={{
            url,
            title,
            text,
        }}
    >
        <IconView>
            <Icon src={icon} />
        </IconView>
    </WebShare>
  );
}
