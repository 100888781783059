import React from 'react';
import styled from 'styled-components';
import { Share } from '../Header/Share/Share';
import { vh100, hexToCSSFilters } from '../../app/theme';

const Wrapper = styled.div`
    position: fixed;
    width: 100%;
    z-index: 40;
    overflow: auto;
    ${vh100('max')};
    background: #fff;
`;

const Header = styled.header`
    position: relative;
    text-align: center;
    min-height: 40px;
`;

export const MediaBox = styled.div`
    position: relative;

    &:before {
        content: "";
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
        padding-top: ${308/375 * 100}%;
    }

    &:after { /* to clear float */
        content: "";
        display: table;
        clear: both;
    }


    img, video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }
`;

const HeaderBox = styled.div`
    padding: 48px;
    padding-bottom: 0;

    > img {
        max-width: 100%;
        ${hexToCSSFilters('#3aa8a3')}
    }
`;

const ShareButton = styled.div`
    position: absolute;
    z-index: 2;
    right: 24px;
    top: 24px;
`;

const Content = styled.div`
    padding: 33px;
`;

export interface MediaProps {
    media?: () => JSX.Element | undefined;
    header?: () => JSX.Element | undefined;
    share?: string;
    onBack?: () => void;
}

export const Media: React.FC<MediaProps> = (props) => {
    const { media, header, share, children, onBack } = props;

    return (<Wrapper>
        <Header>
            {media && <>{media()}</>}
            {header && <HeaderBox>{header()}</HeaderBox>}
            {share && <ShareButton><Share url={share} /></ShareButton>}
        </Header>
        
        <Content>
            {children}
        </Content>
    </Wrapper>);
}