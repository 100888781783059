import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { asset } from '../../app/helpers';
import useOutsideEvent from '../../app/hooks/useOutsideEvent';
import { hexToCSSFilters } from '../../app/theme';
import { DECORATION_COLORS } from '../../types';

const StyledDecoration = styled.div<{active?: boolean, color?: DECORATION_COLORS}>`
    position: relative;
    width: 100%;
    opacity: ${props => props.active ? 1 : 0};
    transform: scale(${props => props.active ? 1 : .5});
    transition: all .5s;
    ${props => props.color === 'white' ? hexToCSSFilters('#ffffff') : hexToCSSFilters('#3aa8a3')}
`;

const Tooltip = styled.div<TooltipProps>`
    position: absolute;
    ${props => props.vertical === 'top' ? 'bottom: 100%;' : 'top: 100%;'}
    ${props => props.horizontal === 'left' ? 'right: 100%;' : props.horizontal === 'right' ? 'left: 100%;' : `
    left: 50%;
    transform: translateX(-50%);
    `}
    background: #000;
    color: #fff;
    padding: 2em;
`;

interface TooltipProps {
    vertical?: "top" | "bottom",
    horizontal?: "left" | "center" | "right"
};

export interface DecorationProps {
    active?: boolean;
    image: string;
    text?: string;
    tooltip?: TooltipProps;
    color?: DECORATION_COLORS;
}

export const Decoration: React.FC<DecorationProps> = (props) => {
    const { image, text, tooltip } = props;
    const [focus, setFocus] = useState(false);
    const ref = useRef<HTMLElement>();

    useOutsideEvent(ref, () => {
        setFocus(false);
    });

    return (
        <>
            <StyledDecoration
                active={props.active}
                color={props.color}
                onClick={() => setFocus(true)}
                ref={el => { if (el) ref.current = el; }}
            >
                {focus && text && (<Tooltip {...tooltip}>{text}</Tooltip>)}
                <img src={asset(image)} />
            </StyledDecoration>
        </>
    );
};