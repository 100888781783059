import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { I18nSegment, transVal, useTrans } from '../../app/services/i18n';
import { loadUser, selectCurrentUser } from '../../app/features/auth';
import { selectProjectDetails, getProjectDetails, selectLoading, hasProjectBooted, isProjectCompleted, gotProjectBooted, projectCompletedData } from '../../app/features/projects';
import { asset } from '../../app/helpers';
import { ButtonBar, Button } from '../../components/Atoms/Button/Button';
import { Launcher } from '../../components/Games/Launcher/Launcher';
import { Layer, LayerProps } from '../../components/Layer';
import { Map } from '../../components/Map/Map';
import { Modal, Picture, Text } from '../../components/Modal/Modal';
import { Family } from '../../components/Help/People';
import { CenteredSpinner } from '../../components/Spinner';
import { IPeople, IProject, IVillage } from '../../types';
import { Intro } from '../../components/Projects/Info/Intro';
import { Outro } from '../../components/Projects/Info/Outro';

interface ProjectDetailsRouteParams {
    project_id: string;
}

export interface ProjectDetailsProps extends LayerProps {
}

export const ProjectDetails: React.FC<ProjectDetailsProps> = (props) => {
    const history = useHistory();
    const params = useParams<ProjectDetailsRouteParams>();
    const user = useSelector(selectCurrentUser);
    const loading = useSelector(selectLoading);
    const project = useSelector(selectProjectDetails);
    const booted = useSelector(hasProjectBooted);
    const completed = useSelector(isProjectCompleted);
    const completedData = useSelector(projectCompletedData);
    const dispatch = useDispatch();
    const [showIntro, setShowIntro] = useState(true);
    const [showOutro, setShowOutro] = useState(false);
    const [focus, setFocus] = useState<number>();
    const [select, setSelect] = useState<number>();
    const [entering, setEntering] = useState<number>();

    useEffect(() => {
        if (!user || !project) return;
        const userdata = user && project && user.stats ? user.stats[project.id] : undefined;
        const progress = userdata?.progress;
        if (userdata) {
            setFocus(progress || 0);
/*
            // it's the end, my friend
            if (progress && project && (progress === project.games.length - 1)) {
                setShowOutro(true);
            }*/
        } else {
            setFocus(0);
        }
    }, [user, project])

    useEffect(() => {
        dispatch(getProjectDetails(params.project_id));
    }, [params.project_id]);

    useEffect(() => {
        if (completed) {
            dispatch(loadUser());
        }
    }, [completed]);

    // go directly without intro
    useEffect(() => {
        if (project && entering && project.games[entering].type === 'story') {
            enterOn(entering);
        }
    }, [entering, project]);

    const backToList = useCallback(() => {
        history.push(`/projects/`);
    }, []);

    const focusOn = useCallback((index) => {
        setFocus(index);
    }, []);

    const selectOn = useCallback((index, openImmediatly) => {
        setSelect(index);
        if (openImmediatly) {
            setEntering(index);
        }
    }, []);

    const scrollEnd = useCallback((index) => {
        if (select === index) {
            setEntering(index);
        }
    }, [select]);

    const enterOn = useCallback((index) => {
        if (project) {
            const game = project.games[index];
            history.push(`/projects/${project.id}/games/${game.id}`);
        }
    }, [project]);

    if (!project || focus === undefined) {
        return <CenteredSpinner />;
    }

    const game = project.games[focus];

    return (<I18nSegment variants={{ project: project.id }}>
        {showIntro && !booted && 
        <Intro
            project={project}
            image={asset(`projects/${project.id}/intro.svg`)}
            text={transVal(project.intro)}
            onClose={() => { dispatch((gotProjectBooted())); setShowIntro(false); } }
        />}
        {completed && 
        <Outro
            project={project}
            image={asset(`projects/${project.id}/outro.svg`)} 
            text={transVal(project.outro)}
            villages={user?.stats?.villages || []} 
            people={user?.stats?.people || []}
            gift_code={completedData ? completedData.gift_code : undefined}
            onChangeProject={(id) => { setShowOutro(false); history.push(`/projects/`); setTimeout(() => history.push(`/projects/${id}`), 100); }}
            onClose={() => { setShowOutro(false); history.push(`/projects/`); } }
        />}
        {(entering !== undefined) && (<Launcher
            type={game.type}
            text={transVal(game.intro)}
            onPlay={() => enterOn(entering)}
            onBack={() => setEntering(undefined)}
        />)}
        <Layer {...props} grow>
            <Map
                user={user}
                project={project}
                focus={focus}
                onBack={backToList}
                onFocus={focusOn}
                onSelect={selectOn}
                onScrollEnd={scrollEnd}
            />
        </Layer>
    </I18nSegment>);
}
