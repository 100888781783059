export const config = {
    debug: process.env.REACT_APP_DEBUG ? process.env.REACT_APP_DEBUG === 'true' : false,
    dev: ((process.env.NODE_ENV || "development") === "development"),
    api: process.env.REACT_APP_API || "http://localhost:8000",
    api_cache: process.env.REACT_APP_API_CACHE_AWS || "",
    website: process.env.REACT_APP_WEBSITE || "",
    oauth2_google: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    oauth2_facebook: process.env.REACT_APP_FACEBOOK_APP_ID,
    google_analytics: process.env.REACT_APP_ANALYTICS_GA4,
    hotjar_id: process.env.REACT_APP_HOTJAR_ID,
    sentry_dsn: process.env.REACT_APP_SENTRY_DSN,
    sentry_sample_rate: process.env.REACT_APP_SENTRY_RATE || 1.0,
}

var $session = {} as Record<string, any>;
export const session = {
    set: (key: string, value: any) => {
        $session[key] = value;
    },
    get: (key: string) => {
        return $session[key];
    }
}