import React from 'react';
import { I18nSegment } from '../../../app/services/i18n';
import { YouLose as BaseYouLose, YouLoseProps } from '../../../components/Games/Result/YouLose';

export const YouLose: React.FC<YouLoseProps> = (props) => {
    const { project, game } = props;

    return (
        <I18nSegment variants={{ project: project.id, game: game.id }}>
            <BaseYouLose {...props} />
        </I18nSegment>
    );
}
