import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectAuthenticated, selectCurrentUser } from '../app/features/auth';
import { Header as BaseHeader } from '../components/Header/Header';

const Wrapper = styled.div`
    position: fixed;
    z-index: 20;
    top: 35px;
    left: 22px;
    right: 22px;
`;

interface Props {
    mode?: string;
    onLevels: () => void;
    onPeople: () => void;
    onInsertCode: () => void;
    onHelp: () => void;
}

export const Header: React.FC<Props> = (props) => {
    const { mode, onLevels, onPeople, onInsertCode, onHelp } = props;
    const auth = useSelector(selectAuthenticated);
    const user = useSelector(selectCurrentUser);
    
    return (
        <Wrapper>
            <BaseHeader
                mode={mode}
                level={user?.stats?.overall.scores.current || 0}
                action_add={!!auth}
                onButton={(type) => {
                    if (type === 'levels') {
                        onLevels();
                    }
                    if (type === 'people') {
                        onPeople();
                    }
                    if (type === 'add') {
                        onInsertCode();
                    }
                    if (type === 'help') {
                        onHelp();
                    }
                }}
            />
        </Wrapper>
    );
}