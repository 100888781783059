import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import drop_img from './drop.svg';
import bomb_img_1 from './bomb/skull.svg';
import bomb_img_2 from './bomb/elephant.svg';

const IMGS_DROP = [
    [drop_img, 22*.3, 26*.3],
];

const IMGS_BOMB = [
    [bomb_img_1, 216*.07, 208*.07],
    [bomb_img_2, 332*.07, 206*.07],
];

interface StyledDropProps {
    x: number;
    good: boolean;
    img: (string | number)[];
}

const El = styled.div<StyledDropProps>`
    position: absolute;
    width: ${props => props.img[1]}%;
    height: ${props => props.img[2]}%;
    background: url(${props => props.img[0]}) no-repeat;
    background-size: contain;
`;

export interface DropProps {
    id: string;
    speed: number;
    x: number;
    good: boolean;
    catchedHeight: number;
    maxHeight: number;
    onInit: (width: number, height: number) => void;
    onCatchLevel: () => void;
    onFall: () => void;
}

export const Drop: React.FC<DropProps> = (props) => {
    const { id, speed, x, good, catchedHeight, maxHeight, onInit, onCatchLevel, onFall } = props;
    const el = useRef<HTMLDivElement>();
    const req = useRef<number>();
    const pos = useRef<number>(0);
    const phase = useRef("falling");
    
    const img = useMemo(() => good ? IMGS_DROP[0] : IMGS_BOMB[Math.floor(Math.random() * IMGS_BOMB.length)], []);

    const dom = useCallback((_el) => {
        if (_el && !el.current) {
            el.current = _el;
            _el.style.left = `${x}%`;
        }
    }, []);

    useEffect(() => {
        onInit(img[1] as number, img[2] as number);
    }, []);

    useEffect(() => {
        req.current = window.setInterval(() => {
            if (el.current) {
                el.current.style.transform = `translate(-50%, ${pos.current}px)`;
            }
            pos.current++;
            const real_pos = pos.current + (el?.current?.offsetHeight || 0);
            if (phase.current === 'falling' && real_pos >= catchedHeight) {
                onCatchLevel();
                phase.current = 'catched';
            }
            if (phase.current === 'catched' && real_pos >= maxHeight) {
                clearInterval(req.current);
                onFall();
                phase.current = 'killed';
            }
        }, 1000/speed);

        return () => { if (req.current) clearInterval(req.current); }
    }, [el.current]);

    return <El x={x} good={good} img={img} ref={dom} />;
}