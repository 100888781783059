import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  RouteProps,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuthenticated } from "../features/auth";

interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  children: any;
}

export function PrivateRoute({ children, ...rest }: PrivateRouteProps) {
  const auth = useSelector(selectAuthenticated);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}

export function OnlyGuestRoute({ children, ...rest }: PrivateRouteProps) {
  const auth = useSelector(selectAuthenticated);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !auth ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/"
              }}
            />
          )
      }
    />
  );
}