import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { HttpError, IError, IUser } from '../../types';
import * as authService from '../services/auth';


interface AuthState {
    authenticated: boolean;
    authenticating: boolean;
    success?: boolean;
    error?: IError;
    token?: string;
    user?: IUser;
    logged_at?: number; // unix timestamp in seconds
}

const initialState: AuthState = {
    authenticated: false,
    authenticating: false,
};

export const authReducer = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loggingIn: (state, action: PayloadAction<string>) => {
            state.authenticated = false;
            state.authenticating = true;
            /*state.user = {
                email: action.payload,
            }*/
        },
        loggedIn: (state, action: PayloadAction<IUser>) => {
            state.authenticated = true;
            state.authenticating = false;
            state.logged_at = Math.floor(Date.now() / 1000);
            state.user = action.payload;
            state.success = true;
        },
        loggedOut: state => {
            state.authenticated = false;
            state.success = false;
            delete state.user;
        },
        loadSuccess: state => {
            state.success = true;
        },
        loadError: (state, action: PayloadAction<IError | undefined>) => {
            state.error = action.payload;
            state.authenticating = false;
            state.authenticated = false;
            delete state.user;
        },
        dismissSuccess: (state) => {
            state.success = undefined;
        },
        dismissError: (state) => {
            state.error = undefined;
        },
    },
});

export const { loggingIn, loggedIn, loggedOut, loadSuccess, loadError, dismissSuccess, dismissError } = authReducer.actions;

export const checkToken = (jwt: string): AppThunk => dispatch => {
    dispatch(loggingIn("@todo"));

    authService.loadUser(jwt)
    .then((user) => {
        dispatch(loggedIn(user));
    }, (error: HttpError) => {
        authService.logout();
        window.setTimeout(() => { dispatch(loadError(error.response?.data)); }, 1);
    });
}

export const loadUser = (jwt?: string): AppThunk => dispatch => {
    authService.loadUser(jwt)
    .then((user) => {
        dispatch(loggedIn(user));
    }, (error) => {
    });
}

export const logIn = (email: string, password: string): AppThunk => dispatch => {
    dispatch(loggingIn(email));

    authService.login(email, password)
    .then(
        (jwt) => {
            authService.loadUser(jwt).then((user) => {
                dispatch(loggedIn(user));
            });
        },
        (error: HttpError) => {
            dispatch(loadError(error.response?.data));
        }
    );
};

export const logOut = (): AppThunk => dispatch => {

    authService.logout()
    .then(
        () => {
            dispatch(loggedOut());
        },
        (error: HttpError) => {
            dispatch(loadError(error.response?.data));
        }
    );
};

export const register = (data: Partial<IUser>): AppThunk => dispatch => {
    authService.register(data)
    .then(
        (jwt) => {
            authService.loadUser(jwt).then((user) => {
                dispatch(loggedIn(user));
            });
        },
        (error: HttpError) => {
            dispatch(loadError(error.response?.data));
        }
    );
};

export const socialLogin = (provider: string, data: Partial<IUser>, providerData: any): AppThunk => dispatch => {
    authService.socialLogin(provider, data, providerData)
    .then(
        (jwt) => {
            authService.loadUser(jwt).then((user) => {
                dispatch(loggedIn(user));
            });
        },
        (error: HttpError) => {
            dispatch(loadError(error.response?.data));
        }
    );
};

export const sendResetPasswordMail = (email: string): AppThunk => dispatch => {
    authService.sendResetPasswordMail(email)
    .then(
        () => {
            dispatch(loadSuccess());
        },
        (error: HttpError) => {
            dispatch(loadError(error.response?.data));
        }
    );
};

export const setNewPassword = (data: authService.setNewPasswordArgs): AppThunk => dispatch => {
    authService.setNewPassword(data)
    .then(
        () => {
            dispatch(loadSuccess());
        },
        (error: HttpError) => {
            dispatch(loadError(error.response?.data));
        }
    );
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAuthenticated = (state: RootState) => state.auth.authenticated;
export const selectAuthenticating = (state: RootState) => state.auth.authenticating;
export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectSuccess = (state: RootState) => state.auth.success;
export const selectError = (state: RootState): IError | undefined => state.auth.error;

export default authReducer.reducer