import React from 'react';
import { I18nSegment } from '../../../app/services/i18n';
import { YouWin as BaseYouWin, YouWinProps } from '../../../components/Games/Result/YouWin';

export const YouWin: React.FC<YouWinProps> = (props) => {
    const { project, game } = props;

    return (
        <I18nSegment variants={{ project: project.id, game: game.id }}>
            <BaseYouWin {...props} />
        </I18nSegment>
    );
}
