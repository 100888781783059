import { config } from "./config";
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { History } from 'history'

export function startTracking( appHistory: History ) {
    // initializing
    var ga = config.google_analytics;
    if (ga) {
        ReactGA.initialize(ga)
        ReactGA.set({ anonymizeIp: true })
        ReactGA.set({ page: window.location.pathname })
    } else {
        console.log('GA4', 'initialize');
    }
    const hjid = config.hotjar_id;
    const hjsv = 6;
    if (hjid) {
        hotjar.initialize(hjid as unknown as number, hjsv);
    } else {
        console.log('HotJar', 'initialize');
    }
    pageView(window.location.pathname);

    // listening for route changes
    appHistory.listen( (location: any) => {
        const url = location.pathname + location.search;
        if (ga) {
            ReactGA.set({ anonymizeIp: true });
            ReactGA.set({ page: url });
        }
        pageView(url);
    });
}

export function pageView( path: string, title?: string ) {
    const ga = config.google_analytics;
    if (ga) {
        ReactGA.send({ hitType: "pageview", page: path, title });
    } else {
        console.log('GA4', 'pageview', path, title);
    }
    const hjid = config.hotjar_id;
    if (hjid) {
        hotjar.stateChange(path);
    } else {
        console.log('HotJar', 'stateChange', path);
    }
}

export function startCapturingErrors() {
    if (config.sentry_dsn) {
        Sentry.init({
            dsn: config.sentry_dsn,
            integrations: [new Integrations.BrowserTracing()],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,
        });
    }
}