import React, { useState, useEffect } from 'react';
import { useLocation, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { checkToken, selectAuthenticated, selectAuthenticating, selectCurrentUser } from '../../app/features/auth';
import { hasToken, getToken } from '../../app/services/auth';
import { Layer, LayerProps } from '../../components/Layer';
import { Splash } from '../../components/Splash/Splash';

const Wrapper = styled(Layer)`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export type INIT_PHASES = "goto_login" | "trying" | "ready" | "none";

export const CheckSession: React.FC<LayerProps> = (props) => {
    const [phase, setPhase] = useState<INIT_PHASES>();
    const [showSplash, setShowSplash] = useState(true)
    const location = useLocation();
    const auth = useSelector(selectAuthenticated);
    const isLogging = useSelector(selectAuthenticating);
    const user = useSelector(selectCurrentUser);
    const dispatch = useDispatch();

    // trying to continue session
    useEffect(() => {
        
        const $checkToken = async (auth: boolean) => {
            if (auth) {
                setPhase("ready");
                return ;
            } else if (hasToken()) {
                if (!isLogging) {
                    const jwt = getToken();
                    dispatch(checkToken(jwt!));
                    setPhase("trying");
                }
            } else {
                // prevent to show auth in reset password page (ugly)
                if (location.pathname.indexOf('reset_password') !== -1) {
                    setPhase("none");
                } else {
                    setPhase("goto_login");
                }
            }
        };
    
        $checkToken(auth);
    }, [isLogging, auth]);

    if (phase === 'none') return null;
    if (!showSplash && (phase === "ready" || phase === "goto_login")) return <Redirect to={'/projects'} />;
    
    return <Wrapper {...props} grow>
        <Splash auth={!!getToken()} phase={phase} onClose={() => setShowSplash(false)} />
    </Wrapper>;
}