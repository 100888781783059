import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { IPeopleImages } from '../../types';
import { asset } from '../../app/helpers';
import { LocalCenteredSpinner } from '../Spinner';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import { Image } from '../Image';
import { MediaBox } from './Media';

SwiperCore.use([Pagination]);

const Wrapper = styled.div`
    position: relative;
    background-color: #DEEEE9;
    --swiper-navigation-size: 22px;
    --swiper-pagination-color: #fff;
    --swiper-theme-color: #0098DA;

    .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        background-color: #fff;
        border: 2px solid #0098DA;
    }

    ${MediaBox} {
        background-color: #DEEEE9;
    }
`;

export interface GalleryProps {
    images: IPeopleImages;
}

export const Gallery: React.FC<GalleryProps> = (props) => {
    const { images } = props;
    const { path, meta, cover } = images;
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState();
    const [error, setError] = useState(false);

    useEffect(() => {
        if (meta) {
            axios.get(asset(`${path}${meta}`))
            .then(res => {
                const { images } = res.data;
                setTotal(images);
            })
            .catch(e => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
        }
    }, [meta]);

    if (error) return null;

    return (<Wrapper>
        {loading && <LocalCenteredSpinner />}
        {total === 0 && <MediaBox><Image src={asset(`${path}${cover}`)} /></MediaBox>}
        {(total && total > 0) &&
        <Swiper
            spaceBetween={0}
            slidesPerView={1}
            pagination={total && (total > 1) ? { clickable: true } : false}
        >
            {[...Array(total)].map((_, index) => {
                const num = index+1;
                return <SwiperSlide key={num}>
                    <MediaBox>
                        <img src={asset(`${path}${num}.jpg`)} />
                    </MediaBox>
                </SwiperSlide>
            })}
        </Swiper>
        }
    </Wrapper>);
}