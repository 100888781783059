import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history'
import { config } from './app/services/config';
import { I18n, initI18n } from "./app/services/i18n";
import { startTracking, startCapturingErrors } from './app/services/tracking';
import { http } from './app/services/http';
import { getStore } from './app/store';
import { GlobalStyle } from './app/theme';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

(async () => {
    const appHistory = createBrowserHistory();
    await initI18n(async () => {
        const url = `${process.env.REACT_APP_I18N_JSON}?force=${+new Date()}`;
        if (url) {
            const res = await http.request<any>({
                url: `${config.api}/api/boot`,
            });
            return res.data;
        }
    });

    startCapturingErrors();

    ReactDOM.render(
        <React.StrictMode>
            <Provider store={getStore()}>
                <GlobalStyle />
                {/* 
        // @ts-ignore */}
                <I18n render={() => <App appHistory={appHistory} />} />
            </Provider>
        </React.StrictMode>,
        document.getElementById('root')
    );

    startTracking(appHistory);

    // @todo if necessary, add a banner to promote PWA installation
    // https://web.dev/customize-install/

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorkerRegistration.register();

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
})();
