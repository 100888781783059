import React, { useCallback, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTrans } from '../../../app/services/i18n';
import { doesRangesOverlap, genUID, inRange } from '../../../app/helpers';
import { GameEvents } from '../Common';
import { Cursor, SIZE as CursorSize } from './Cursor';
import { Drop } from './Drop';
import { Time } from '../Time';
import { useSFX } from '../../../app/hooks/useSFX';
import { config } from '../../../app/services/config';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Header = styled.header`
    flex: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    margin-top: 48px;
    margin-bottom: 20px;

    > * {
        padding: 0 10px;
        &:first-child { left: 0; color: #00AFAA; }
        &:last-child { right: 0; color: #1DA7E0; }
    }
`;

const Stage = styled.section`
    flex: 1;
    position: relative;
    overflow: hidden;
`;

const Track = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70px;
`;

const LIVES = 3;
const TIMEOUT = 20;

interface DropObj {
    id: string;
    speed: number;
    x: number;
    good: boolean;
}

export interface WaterCatchProps extends GameEvents {
}

export const WaterCatch: React.FC<WaterCatchProps> = (props) => {
    const { onWin, onLoss } = props;
    
    const { t } = useTrans();
    const sfx = useSFX();
    const [boardSize, setBoardSize] = useState<number[]>([]);
    const [drops, setDrops] = useState<DropObj[]>([]);
    const [dropsSize, setDropSize] = useState({})
    const [cursorSize, setCursorSize] = useState([0,0])
    const [lives, setLives] = useState<number>(LIVES);
    const [score, setScore] = useState<number>(0);
    const [playing, setPlaying] = useState(true);
    const el = useRef<HTMLDivElement>();
    const timer = useRef<number>();
    const generator = useRef<number>();
    const hardness = useRef<number>(0);
    const cursor = useRef<number>(0);

    const dom = useCallback((_el: HTMLDivElement) => {
        if (_el && !el.current) {
            el.current = _el;
            setBoardSize([el.current?.offsetWidth, el.current?.offsetHeight]);
        }
    }, []);

    const generateDrop = useCallback(() => {
        if (!playing) return;
        // calculate by hardness
        const speed = 1000 + hardness.current * 1;
        const delay = 1000 - hardness.current * 10;
        const good = Math.random() >= .5;
        const offset = 5; // percent

        // generate
        setDrops( drops => {
            const drop = {
                id: genUID(),
                speed,
                x: offset + Math.random() * (100 - offset*2),
// x: 50,
                good
            }
            const newDrops = [...drops];
            newDrops.push(drop)
            return newDrops;
        });

        // relaunch
        generator.current = window.setTimeout(() => {
            generateDrop();
        }, delay);
    }, [playing, boardSize]);

    const arrivedToLevelDrop = useCallback((drop: DropObj) => {
        if (!playing) return;
        const size = (dropsSize as any)[drop.id];
        // if (inRange(drop.x, cursor.current, CursorSize[0]/2)) {
        if (doesRangesOverlap(
            drop.x-size[0]/2,
            drop.x+size[0]/2,
            cursor.current-CursorSize[0]/2,
            cursor.current+CursorSize[0]/2
        )) {
            // taken
            if (drop.good) {
                if (config.debug) console.log('taken')
                setScore(score => score+1);
                sfx.correct();
            } else {
                if (config.debug) console.log('bomb')
                youLose();
                sfx.wrong();
            }
        } else {
            if (drop.good) {
                // missed
                if (config.debug) console.log('missed')
                setScore(score => score-1);
                setLives(lives => lives-1);
            }
        }
    }, [cursor, score, playing, dropsSize]);

    const falledDrop = useCallback((drop: DropObj) => {
        if (!playing) return;
        setDrops(drops => {
            const newDrops = drops.filter(d => d.id !== drop.id);
            return newDrops;
        });
        
    }, [cursor, score, playing]);

    const stop = useCallback(() => {
        if (timer.current) { clearInterval(timer.current); }
        if (generator.current) { clearTimeout(generator.current); }
        setPlaying(false);
    }, [timer.current, generator.current]);

    const youWin = useCallback(() => {
        onWin();
        //stop();
    }, []);

    const youLose = useCallback(() => {
        onLoss();
        //stop();
    }, []);

    useEffect(() => {
        return () => {
            stop();
        }
    }, []);

    useEffect(() => {
        if (boardSize[0]) {
            // drops factory
            generateDrop();

            timer.current = window.setInterval(() => {
                hardness.current++;
            }, 5000);
        }
    }, [boardSize, generateDrop]);

    useEffect(() => {
        if (lives === 0) {
            youLose();
        }
    }, [lives]);

    return <Wrapper ref={dom}>
        <Header>
            <div>{t('WaterCatch.time')}: <Time timeout={TIMEOUT} onZero={youWin} /></div>
            <div>{t('WaterCatch.lives')}: {lives}</div>
        </Header>
        <Stage>
            {boardSize[1] !== undefined && (
            <>
            {drops.map((drop) => {
                return <Drop
                    key={drop.id}
                    id={drop.id}
                    speed={drop.speed}
                    x={drop.x}
                    good={drop.good}
                    catchedHeight={boardSize[1] - cursorSize[1]}
                    maxHeight={boardSize[1]}
                    onInit={(w, h) => { setDropSize(dropsSize => ({...dropsSize, [drop.id]: [w,h]})); }}
                    onCatchLevel={() => { arrivedToLevelDrop(drop); } }
                    onFall={() => { falledDrop(drop); }}
                />
            })}
            </>
            )}
            <Track>
                <Cursor track={boardSize[0]} onInit={(w, h) => setCursorSize([w,h])} onMove={(x) => { cursor.current = x; }} />
            </Track>
        </Stage>
    </Wrapper>;
}
