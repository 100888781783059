import React, { useCallback, useContext, useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';

/**
 * Main modal structure
 */
const Underlay = styled.div`
    position: fixed;
    z-index: 14;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255,255,255,.1);
    touch-action: none;
`;

const Wrapper = styled.div<{
    z: number;
    bg?: string,
    bottom?: boolean;
    noHeader?: boolean;
}>`
    position: fixed;
    z-index: ${props => props.z};
    left: 24px;
    right: 24px;
    top: ${props => props.noHeader ? '0' : '44px'};
    bottom: ${props => props.bottom ? '90px' : '0'};
    overflow: auto;
    padding: 23px; //50px;
    padding-top: ${props => props.noHeader ? '60px' : '96px'};
    background: #fff;
    ${props => props.bg ? `background-image: url(${props.bg}); background-size: contain;` : null}

    ${props => props.noHeader ? `` : `
    &:after {
        content: '';
        position: fixed;
        left: 24px;
        right: 24px;
        top: 44px;
        height: 100px;
        pointer-events: none;
        background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%);
    }`}
`;

const BackBar = styled.nav`
    position: fixed;
    z-index: 15;
    bottom: 20px;
    right: 20px;
`;

export interface ModalProps {
    id: string;
    bg?: string;
    bottom?: any;
    noHeader?: boolean;
    onClose?: () => void;
}

export const Modal: React.FC<ModalProps> = (props) => {
    const { children, id, bg, bottom, noHeader, onClose } = props;
    const {showModal, hideModal, currentModal} = useContext(ModalManagerContext);

    useEffect(() => {
        if (showModal) showModal(id);
        return () => { if (hideModal) hideModal(); }
    }, []);

    return (<>
        <Underlay onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} />
        <Wrapper z={id === currentModal ? 16 : 15} bg={bg} bottom={!!bottom} noHeader={noHeader} onClick={() => { if (onClose) onClose(); }}>
            {children}
        </Wrapper>
        {bottom && <BackBar>{bottom}</BackBar>}
    </>);
}

/**
 * Modal head image
 */
const StyledPicture = styled.h1`
    margin-bottom: 20px;
    
    > img {
        max-width: 100%;
    }
`;

export const Picture: React.FC = (props) => <StyledPicture>{props.children}</StyledPicture>;


/**
 * Modal title
 */
const WrapperTitle = styled.div`
    display: flex;
    justify-content: center;
`;

const StyledTitle = styled.h1`
    display: inline-block;
    text-align: center;
    font-size: 38px; // 48px;
    font-weight: 700;
    line-height: 1;
    color: #3DA05E;
    text-transform: uppercase;
    margin-bottom: 22px; // 58px;
    padding-bottom: 10px;
    border-bottom: 4px solid #0098DA;
`;

export const Title: React.FC = (props) => <WrapperTitle><StyledTitle>{props.children}</StyledTitle></WrapperTitle>;

/**
 * Modal subtitle
 */
const StyledSubTitle = styled.h2`
    font-size: 32px;
    color: #0098DA;
    margin-bottom: 1em;
`;

export const SubTitle: React.FC = (props) => <StyledSubTitle>{props.children}</StyledSubTitle>;

/**
 * Modal text paragraph
 */
const StyledText = styled.p<{size: string}>`
    font-size: ${props => props.size === 'big' ? '20px' : '18px'};
    line-height: 1.166;
    text-align: center;
    color: #5D5C5C;
    margin-bottom: 1em;

    strong {
        color: #0098DA;
    }
    em {
        color: #3DA05E;
    }
`;

export const IntroText = styled.div`
    margin: 0 auto;
    max-width: 220px;
    text-align: center;
`;

interface TextProps {
    size?: 'normal' | 'big';
}

export const Text: React.FC<TextProps> = (props) => {
    const { size = 'normal', children } = props;

    return <StyledText size={size}>{children}</StyledText>;
}

/**
 * Divisor (like <hr>)
 */
const StyledDivider = styled.div`
    height: 35px;
`;

export const Divider: React.FC = () => {
    return <StyledDivider />;
}

/*** Concurrent modal manager */
interface ModalManagerContextReturn {
    showModal: (id: string) => void;
    hideModal: () => void;
    currentModal?: string;
}

const ModalManagerContext = React.createContext({} as ModalManagerContextReturn);
export const ModalManager: React.FC = ({children}) => {
    const [current, setCurrent] = useState<string>();
    const showModal = useCallback((id: string) => {
        setCurrent(id);
        document.body.className = 'freezed';
    }, []);
    const hideModal = useCallback(() => {
        setCurrent(undefined);
        document.body.className = '';
    }, [current]);

    return <ModalManagerContext.Provider value={{
        showModal,
        hideModal,
        currentModal: current
    }}>{children}</ModalManagerContext.Provider>;
}