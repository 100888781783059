import React from 'react';
import styled from 'styled-components';
import img_world from './world.svg';
import bg_world from './world_bg.png';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: url(${bg_world});
    background-size: cover;
    background-position: center;
`;

const Figure = styled.img`
    position: absolute;
    left: 20vw;
    top: 75vh;
    transition: transform .2s;
`;

export interface WorldProps {
    x?: number;
    y?: number;
}

const CANVAS = [3326, 1913];

function pixels2Percent(px: number, axis: 'x' | 'y') {
    return px / CANVAS[axis === 'x' ? 0 : 1] * 100;
}

const SCALE = .6;
const WORLD = {
    width: CANVAS[0] * SCALE,
    height: CANVAS[1] * SCALE
};

export const World: React.FC<WorldProps> = (props) => {
    const { x = CANVAS[0]/2, y = CANVAS[1]/2 } = props;

    return (
        <Wrapper>
            <Figure style={{...WORLD, transform: `translate(${pixels2Percent(-x, 'x')}%, ${pixels2Percent(-y, 'y')}%)`}} src={img_world} />
        </Wrapper>
    );
}
