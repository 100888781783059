import React from 'react';
import styled from 'styled-components';
import { useTrans, transVal } from '../../../app/services/i18n';
import { Button, ButtonBar } from '../../Atoms/Button/Button';
import { Modal, Text } from '../../Modal/Modal';
import { GAME_TYPES } from '../../../types';
import default_img from './default.png';
import tictactoe_img from './tictactoe.svg';
import quiz_img from './quiz.svg';
import survey_img from './survey.svg';
import memory_img from './memory.svg';
import find_diff_img from './find_diff.svg';
import water_catch_img from './water_catch.svg';
import tictactoe_title from './titles/tictactoe.svg';
import quiz_title from './titles/quiz.svg';
import survey_title from './titles/survey.svg';
import memory_title from './titles/memory.svg';
import find_diff_title from './titles/find_diff.svg';
import water_catch_title from './titles/water_catch.svg';
import { renderHTML } from '../../helpers';

const Title = styled.header<{size: number}>`
    text-align: center;
    margin-bottom: 25px;

    > img {
        width: ${props => props.size}px;
    }
`;

const FooterBar = styled.nav`
    /*position: absolute;
    bottom: 72px;
    left: 0;*/
    width: 100%;
    text-align: center;
    
    > * + * {
        margin-top: 18px;
    }
`;

export interface LauncherProps {
    type: GAME_TYPES;
    text: string;
    onPlay?: () => void;
    onBack?: () => void;
};

export const LauncherBackgrounds = {
    'default': default_img,
    'tictactoe': tictactoe_img,
    'quiz': quiz_img,
    'survey': survey_img,
    'memory': memory_img,
    'find_diff': find_diff_img,
    'water_catch': water_catch_img,
    'story': undefined,
};

export const LauncherTitles = {
    'tictactoe': tictactoe_title,
    'quiz': quiz_title,
    'survey': survey_title,
    'memory': memory_title,
    'find_diff': find_diff_title,
    'water_catch': water_catch_title,
    'story': undefined,
};

const LauncherTitlesSize = {
    'tictactoe': 101,
    'quiz': 138,
    'survey': 138,
    'memory': 92,
    'find_diff': 146,
    'water_catch': 105,
    'story': 100,
}

export const Launcher: React.FC<LauncherProps> = (props) => {
    const { type, text, onPlay, onBack } = props;
    const { t } = useTrans();

    return (<>
        <Modal id='Launcher' /*bg={LauncherBackgrounds[type]}*/>
            <Title size={LauncherTitlesSize[type]}>
                <img src={LauncherTitles[type]} />
            </Title>
            <Text>{renderHTML(transVal(text))}</Text>
            <FooterBar>
                <Button
                    text={t('Launcher.play')}
                    variant="primary"
                    onPress={() => { if (onPlay) onPlay(); }}
                />
                <Button
                    icon={'back'}
                    text={t('Launcher.back')}
                    variant="action"
                    onPress={() => { if (onBack) onBack(); }}
                />
            </FooterBar>
        </Modal>
    </>);
}
