import React from 'react';
import SocialLogin from 'react-social-login';
import { Button } from '../../components/Atoms/Button/Button';

interface SocialButtonProps {
    icon: string;
    color: string;
    triggerLogin: () => void;
    children: React.ReactNode;
}

class SocialButton extends React.Component<SocialButtonProps> {
    render() {
        const { triggerLogin, icon, ...rest } = this.props;
        return (
            <Button icon={icon} variant="action" onPress={this.props.triggerLogin} {...rest} />
        );
    }
}

export default SocialLogin(SocialButton);