import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.nav`
    position: fixed;
    bottom: 0;
    left: 0;
    background: #ff0000;
`;

export const DebugBar: React.FC = ({ children }) => {
    return (
        <Wrapper>{children}</Wrapper>
    );
}