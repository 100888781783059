import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonBar } from '../../components/Atoms/Button/Button';
import { Input } from '../../components/Atoms/Input/Input';
import { QRCodePicker } from '../../components/Atoms/Input/QRCodePicker';
import { insertCode, insertCodeSuccessPurge, insertCodeFailurePurge, selectInsertCodeSuccess, selectInsertCodeError, selectPinnedCode, pinnedCodePurge } from '../../app/features/codes';
import { Modal, Title, Text, IntroText } from '../../components/Modal/Modal';
import { Alert } from '../../components/Alert';
import { YouHelped } from '../../components/Modal/YouHelped';
import qrcode_image from './qrcode.svg';
import { useTrans, getCurrentLang, I18nSegment } from '../../app/services/i18n';
import { getProjects } from '../../app/features/projects';

const QRCodeButton = styled.div`
    margin-bottom: 1em;

    > label {
        display: block;
        font-size: 15px;
        color: #3DA05E;
        margin: 8px;
    }

    > img {
        display: block;
        margin: 0 auto;
        padding: 10px;
        border: 3px solid #1DA7E0;
        border-radius: 16px;
    }
`;

export interface InsertCodeProps {
    onBack?: () => void;
}

const extractCode = (txt: string) => {
    // const $test = 'https://code.wa-mi.org/123stella';
    const res1 = /.*\/(.*)/im.exec(txt);
    if (res1 && res1[1]) {
        return res1[1];
    }
    // const $test = '123stella'; const $notOk = 'foo\123stella';
    const res2 = /^([\w]*){1}$/im.exec(txt);
    if (res2 && res2[1]) {
        return res2[1];
    }
}

export const InsertCode: React.FC<InsertCodeProps> = (props) => {
    const { onBack } = props;

    const { t, h } = useTrans();
    const [code, setCode] = useState<string>();
    const [showQRCode, setShowQRCode] = useState<boolean>();
    const insertedCode = useSelector(selectInsertCodeSuccess);
    const error = useSelector(selectInsertCodeError);
    const pinnedCode = useSelector(selectPinnedCode);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        setCode(pinnedCode);
    }, [pinnedCode]);
    
    const onChange = useCallback((value) => {
        const newCode = extractCode(value);
        if (newCode) {
            setCode(newCode);
        } else {
            setCode('');
        }
    }, [code]);

    const onSubmit = useCallback(() => {
        if (code) {
            dispatch(insertCode(code));
        }
    }, [code]);

    const onSuccessPurge = useCallback(() => {
        dispatch(insertCodeSuccessPurge());
    }, []);

    const onErrorPurge = useCallback(() => {
        dispatch(insertCodeFailurePurge());
    }, []);

    const onExit = useCallback(() => {
        dispatch(getProjects());
        if (onBack) onBack();
    }, [onBack]);

    if (insertedCode) {
        const { data_people: people, data_project: project } = insertedCode;
        return (
            <I18nSegment variants={{ project: project.id }}>
                <YouHelped
                    url={t('People.share_url', { people_id: people.id, lang: getCurrentLang()} )}
                    code={insertedCode}
                    onPlay={() => { history.push(`/projects/${project.id}`); onSuccessPurge(); onExit(); }}
                    onInsertCode={() => { onSuccessPurge(); onErrorPurge(); }}
                    onBack={() => { onSuccessPurge(); onExit(); }}
                />
            </I18nSegment>
        )
    }

    return (
        <>
            {error && error.error && <Alert onClose={onErrorPurge} delayClose={error.code === 203 ? 5000 : undefined}>{t(`${error.error}`)}</Alert>}
            {showQRCode && <QRCodePicker
                    onChange={(val) => {
                        onChange(val);
                        onSubmit();
                        setShowQRCode(false);
                    }}
                    onCancel={() => {
                        setShowQRCode(false);
                    }}
                />}
            <Modal id='InsertCode'>
                <Title>{t('InsertCode.title')}</Title>
                <Text>
                    <IntroText>
                        {t('InsertCode.text')}
                    </IntroText>
                </Text>
                <Input
                    label={t('InsertCode.input_label')}
                    value={code}
                    onChange={(val) => onChange(val)}
                />
                {/*
                <QRCodeButton onClick={() => setShowQRCode(true)}>
                    <label>Inquadra il QR</label>
                    <img src={qrcode_image} />
                </QRCodeButton>
                */}
                <ButtonBar>
                    <Button
                        text={t('InsertCode.submit')}
                        variant="primary"
                        onPress={() => onSubmit()}
                    />
                </ButtonBar>
                <ButtonBar>
                    <Button
                        text={t('InsertCode.back_home')}
                        variant="action"
                        onPress={onExit}
                    />
                </ButtonBar>
            </Modal>
        </>
    );
}
