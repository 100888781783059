import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../app/features/auth';
import { selectProjects } from '../app/features/projects';
import { Levels as BaseLevels } from '../components/Help/Levels/Levels';

export interface LevelProps {
    onBack: () => void;
}

export const Levels: React.FC<LevelProps> = (props) => {
    const { onBack } = props;
    const projects = useSelector(selectProjects);
    const user = useSelector(selectCurrentUser);
    
    return (
        <BaseLevels
            projects={projects}
            stats={user?.stats}
            onBack={onBack}
        />
    );
}