import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTrans, transVal } from '../../../app/services/i18n';
import { asset } from '../../../app/helpers';
import { Button, ButtonBar } from '../../Atoms/Button/Button';
import { Media, MediaBox } from '../../Modal/Media';
import { IGameStory, IMatchData } from '../../../types';
import { renderHTML } from '../../helpers';
import { vh100 } from '../../../app/theme';

const MediaPlayer = styled.figure`
    position: relative;
    width: 100%;
    ${vh100()};
    background-color: #000;
`;

const Image = styled.img`
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
`;

const Video = styled.video`
    width: 100%;
    background-color: #000;
`;

const Animation = styled.video`
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
`;

const Text = styled.div`
    font-size: 18px;
    line-height: 1.22;
    margin-bottom: 34px;
`;

const HTML = styled.div`
    font-size: 18px;
    line-height: 1.22;
    margin-bottom: 10px;

    h1, h2, h3 {
        font-size: 26px;
        font-weight: 700;
        line-height: 1.16;
    }
    h1 {
        color: #3DA05E;
    }
    h2 {
        color: #0098DA;
    }
    h3 {
    }

    p {
        &:first-of-type {
            margin-top: 2em;
        }
        margin-bottom: 1em;
    }

    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid transparent;
        height: 38px;
        border-radius: 19px;
        padding: 0 1em;
        text-decoration: none;
        font-weight: 700;
        word-break: keep-all;
        white-space: nowrap;
        color: #fff;
        background-color: #00A19A;

        &.secondary {
            color: #00A19A;
            border-color: #00A19A;
            background-color: #fff;
        }
        &.action {
            color: #00A19A;
            border-color: #E6E6E6;
            background-color: #E6E6E6;
        }
        &.light {
            color: #fff;
            border-color: #37AF73;
            background-color: #37AF73;
        }
        &.link {
            color: #706F6F;
            border-color: none;
            background-color: #fff;
        }
    }
`;

export interface StoryProps extends IGameStory {
    onClose?: (data?: IMatchData) => void;
};

export const Story: React.FC<StoryProps> = (props) => {
    const { header, text, image, video, animation, onClose } = props;

    const { t } = useTrans();
    const [step, setStep] = useState(0);

    const readMore = useCallback(() => {
        setStep(step => step+1);
    }, []);

    if (video) {
        return (<>
            <Media media={() => <MediaBox><Video width="320" height="240" autoPlay controls><source src={asset(video)} type="video/mp4" /></Video></MediaBox>}>
                <Text>{renderHTML(transVal(text))}</Text>
                <ButtonBar>
                    <Button icon='back' variant='action' onPress={onClose}>{t('Story.back')}</Button>
                </ButtonBar>
            </Media>
        </>);
    }

    return (<>
        {step === 0 && (
        <MediaPlayer>
            {image && <Image src={asset(image)} onClick={readMore} />}
            {animation && <Animation preload='auto' autoPlay muted loop playsInline width="320" height="240" onClick={readMore}><source src={asset(animation)} type="video/mp4" /></Animation>}
        </MediaPlayer>
        )}
        {step === 1 && (
        <Media header={() => header ? <img src={asset(header)} /> : undefined}>
            <HTML>{renderHTML(transVal(text))}</HTML>
            <ButtonBar>
                <Button icon='back' variant='action' onPress={onClose}>{t('Story.back')}</Button>
            </ButtonBar>
        </Media>
        )}
    </>);
}
