import React from 'react';
import styled from 'styled-components';
import back_img from './back.svg';

const Btn = styled.button<Partial<ButtonProps>>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: 19px;
    padding: 0 1em;
    text-decoration: none;

    ${props => props.size === 'normal' ? `
        height: 38px;
        min-width: 140px;
        font-size: 18px;
    ` : null}

    ${props => props.size === 'small' ? `
        height: 26px;
        min-width: 86px;
        font-size: 12px;
    ` : null}

    ${props => props.size === 'big' ? `
        height: 38px;
        min-width: 200px;
        font-size: 24px;
    ` : null}

    > img {
        height: 18px;
        margin-right: 10px;
    }

    > span {
        // flex: 1;
        font-weight: 700;
        word-break: keep-all;
        white-space: nowrap;
    }

    ${props => props.variant === 'primary' ? `
        color: #fff;
        background-color: #00A19A;
    ` : null}

    ${props => props.variant === 'secondary' ? `
        color: #00A19A;
        border-color: #00A19A;
        background-color: #fff;
    ` : null}

    ${props => props.variant === 'action' ? `
        color: #00A19A;
        border-color: #E6E6E6;
        background-color: #E6E6E6;
    ` : null}

    ${props => props.variant === 'light' ? `
        color: #fff;
        border-color: #37AF73;
        background-color: #37AF73;
    ` : null}

    ${props => props.variant === 'link' ? `
        color: #706F6F;
        border-color: none;
        background-color: #fff;
    ` : null}

    ${props => props.disabled ? `
        color: #fff;
        background-color: #8BAAB2;
    ` : null}
`;

export interface ButtonProps {
    text?: string;
    icon?: string;
    href?: string;
    target?: "_blank";
    variant?: "primary" | "secondary" | "action" | "light" | "link";
    size?: "small" | "normal" | "big";
    disabled?: boolean;
    onPress?: () => void;
}

export const Button: React.FC<ButtonProps> = ({ children, text, icon, href, target, variant = 'primary', size = 'normal', disabled, onPress }) => {
    if (icon === 'back') icon = back_img;
    return (
        <Btn
            as={href ? 'a' : 'button'}
            href={href ? href : undefined}
            onClick={() => { if (!disabled && onPress) onPress(); }}
            variant={variant}
            size={size}
            disabled={disabled}
            icon={icon}
            target={target}
        >
            {icon && <img src={icon} />}
            <span>{text || children}</span>
        </Btn>
    );
}

const LinkBtn = styled.div`
    display: block;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    color: #00A19A;
`;

export const LinkButton: React.FC<ButtonProps> = ({ children, text, icon, variant = 'primary', disabled, onPress }) => {
    return (
        <LinkBtn onClick={() => { if (!disabled && onPress) onPress(); }}>
            {text || children}
        </LinkBtn>
    );
}

const RoundBtn = styled.button<{size?: number}>`
    display: inline-flex;
    width: ${props => props.size || 60}px;
    height: ${props => props.size || 60}px;
    color: #fff;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    border: none;

    > img {
        height: ${props => props.size ? props.size/2 : 32}px;
    }
`;

export interface RoundButtonProps {
    id?: string;
    icon: string;
    color: string;
    size?: number;
    onPress?: () => void;
}

export const RoundButton: React.FC<RoundButtonProps> = ({ id, icon, color, size, onPress }) => {
    return (
        <RoundBtn id={id} onClick={onPress} style={{backgroundColor: color}} size={size}>
            <img src={icon} />
        </RoundBtn>
    );
}

const WrapperButtonBar = styled.nav`
    display: flex;
    gap: 16px;

    > * {
        flex: 1;
    }

    & + & {
        margin-top: 1em;
    }
`;

export interface ButtonBarProps {
}

export const ButtonBar: React.FC<ButtonBarProps> = (props) => {
    return (
        <WrapperButtonBar>
            {props.children}
        </WrapperButtonBar>
    );
}