import React from 'react';
import styled from 'styled-components';
import { ICode, RecursivePartial } from '../../types';
import { useTrans, transVal } from '../../app/services/i18n';
import { Button, ButtonBar } from '../Atoms/Button/Button';
import { Title, Text } from './Modal';
import { Media } from './Media';
import { Gallery } from './Gallery';

const Centered = styled.div`
    text-align: center;
`;

const Family = styled.div`
    font-size: 22px;
    font-weight: 700;
    line-height: 1.16;
    color: #00A19A;
    margin-bottom: 18px;
`;

export interface YouHelpedProps {
    url: string;
    code: ICode;
    onPlay?: () => void;
    onInsertCode?: () => void;
    onBack?: () => void;
}

export const YouHelped: React.FC<YouHelpedProps> = (props) => {
    const { url, code, onPlay, onInsertCode, onBack } = props;
    const { data_people: people, data_project: project, data_village: village, liters } = code;

    const { t, h } = useTrans();

    return (
        <Media
            media={() => people && people.images ? <Gallery images={people.images} /> : undefined}
            share={url}
        >
            <Title>{h('YouHelped.title', undefined, { nl2br: true })}</Title>
            
            <Centered>
                <Family>
                    {h('YouHelped.people_data', {
                        liters: liters || 0,
                        people: people && people.name ? people.name.toUpperCase() : "",
                        project: project?.name?.toUpperCase() || "",
                        village: village?.name?.toUpperCase() || "",
                    })}
                </Family>
                {people.text && <Text>{transVal(people.text)}</Text>}
            </Centered>
            {project && project.state && project.state.is_active && 
            <ButtonBar>
                <Button
                    text={t('YouHelped.play_code')}
                    variant="primary"
                    onPress={() => { if (onPlay) onPlay(); }}
                />
            </ButtonBar>
            }
            {t('YouHelped.project_link_url') !== 'YouHelped.project_link_url' && 
            <ButtonBar>
                <Button
                    href={t('YouHelped.project_link_url')}
                    text={t('YouHelped.project_link_label')}
                    variant="primary"
                    target="_blank"
                />
            </ButtonBar>
            }
            <ButtonBar>
                <Button
                    text={t('YouHelped.insert_code')}
                    variant="light"
                    onPress={() => { if (onInsertCode) onInsertCode(); }}
                />
            </ButtonBar>
            <ButtonBar>
                <Button
                    text={t('YouHelped.back_home')}
                    variant="action"
                    icon='back'
                    onPress={() => { if (onBack) onBack(); }}
                />
            </ButtonBar>
        </Media>
    );
}
