export function asset(src: string) {
    if (src.substr(0,1) === '@') return src.substr(1);
    if (src.substr(0,4) === 'http') return src;
    return `${process.env.REACT_APP_ASSET_AWS}${src}`;
}

export function strSplit(str: string, length: number) {
    return str.match(new RegExp('.{1,' + length + '}', 'g')) as string[];
}

export function trimSlash(str: string) {
    return str.replace(/^\/|\/$/g, '');
}

export function generateItems(num: number): number[] {
    return Array.from(Array(num).keys());
}

export function genUID() {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substr(2, 9);
};

export function inRange( val: number, near: number, tolerance: number ) {
    if (
        (val > near - tolerance) && (val < near + tolerance)
    ) {
        return true;
    }
    return false;
}

export function doesRangesOverlap(x1: number, x2: number, y1: number, y2: number) {
    return x1 <= y2 && y1 <= x2;
}

export function shuffle(a: any[]) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.round(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
}
