import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useSFX } from '../../../app/hooks/useSFX';
import { useTrans } from '../../../app/services/i18n';
import { Text, Modal } from '../../../components/Modal/Modal';
import { IGame, IProject } from '../../../types';
import { Button, ButtonBar } from '../../Atoms/Button/Button';
import img from './win.svg';

const Picture = styled.figure`
    text-align: center;

    > img {
        height: 52px;
    }
`;

const Title = styled.h1`
    text-align: center;
    font-size: 45px;
    font-weight: 700;
    color: #00AFAA;
    text-transform: uppercase;
    margin-bottom: 18px;
`;

export interface YouWinProps {
    project: Partial<IProject>;
    game: Partial<IGame>;
    onPlay: () => void;
    onClose: () => void;
}

export const YouWin: React.FC<YouWinProps> = (props) => {
    const { project, game, onPlay, onClose } = props;

    const { t } = useTrans();
    const sfx = useSFX();
    useEffect(() => {
        sfx.win();
    }, []);
    const is_free = game.data && game.data.free;

    return (
        <Modal id='YouWin'>
            <Picture><img src={img} /></Picture>
            <Title>{t('Result.win_title')}</Title>
            {is_free && <Text>{t('Result.win_text_free', {project_name: project!.name || ""})}</Text>}
            {!is_free && <Text>{t('Result.win_text', {project_name: project!.name || ""})}</Text>}
            <ButtonBar>
                <Button
                    text={t('Result.next')}
                    variant="primary"
                    onPress={() => { if (onPlay) onPlay(); }}
                />
            </ButtonBar>
        </Modal>
    );
}
