import React, { useContext } from "react";
import { I18n, setHandleMissingTranslation, setTranslations, setLocale, getLocale, getTranslations, t as _t, l, Replacements, TranslateOptions } from "react-i18nify";

const CONSTANTS = {
  langs: ['it', 'en'],
  localStorageKey: 'lang',
}

var currentLang: string;

export const initI18n = async (getter: any) => {
  const res = await getter();
  const translations = res.data;

  const lang = localStorage.getItem(CONSTANTS.localStorageKey) || getLanguageByClient();
  
  setTranslations(translations, true);
  changeLang(lang);
};

export const changeLang = (lang: string) => {
    setLocale(lang, true);
    currentLang = lang;
    localStorage.setItem(CONSTANTS.localStorageKey, lang);
};

export const getLangs = () => CONSTANTS.langs;

export const getCurrentLang = () => currentLang;

export const getLanguageByClient = () => {
    const $default = CONSTANTS.langs[0];

    let found = [];
    
    const navigator = window.navigator as any;

    if (typeof navigator !== 'undefined') {
      if (navigator.languages) { // chrome only; not an array, so can't use .push.apply instead of iterating
        for (let i=0; i < navigator.languages.length; i++) {
          found.push(navigator.languages[i]);
        }
      }
      if (navigator.userLanguage) {
        found.push(navigator.userLanguage);
      }
      if (navigator.language) {
        found.push(navigator.language);
      }
    }
    
    if (found.length > 0) {
        var _detected: any;
        found.forEach( f => {
           const [l] = f.toLowerCase().split('-'); // like it-IT
           const _langs = CONSTANTS.langs.filter( ll => l === ll )
           if (_langs.length > 0) {
               _detected = _langs[0];
           }
        });
        if (_detected) {
            return _detected;
        }
    }

    return $default;
}

export const transVal = (val: any) => {
  if (typeof val === 'string') return val;
  try {
    return val[currentLang];
  } catch(e) {
    return val;
  }
}

export const TransVal = ({children}: any) => {
  {/* 
  // @ts-ignore */}
  return <I18n render={() => {
    return transVal(children) || null;
  }} />
}

export function t(
  key: string,
  replacements?: Replacements,
  options?: TranslateOptions
) {
  try {
    const variants = ['project'];
    const obj = (replacements as object || {}) as Replacements;
    const keys = Object.keys(obj).reduce<string[]>((acc, k) => {
      if (variants.includes(k)) acc.push(`${key}|${k}:${obj[k]}`);
      return acc;
    }, []);
    keys.push(key);
    keys.push(`Common.${key.split('.')[1]}`);

    const translated = keys.map( (k) => {
      return _t(k, replacements, { returnNullOnError: true });
    } ).find( str => str !== null );

    const params = new URLSearchParams(window.location.search)
    if (params.has('notrans')) {
      const debug = params.get('notrans');
      if (debug === 'common') return keys[keys.length-1];
      if (debug === 'variants') return keys.slice(0, -2).join('▪️');
      return keys[keys.length-2];
    }

    return translated || key;
  } catch(e) {
    return key;
  }
}

interface I18nSegmentProps {
  variants: Record<string, unknown>;
}

interface I18nOptions extends TranslateOptions {
  nl2br?: boolean;
}

const I18nSegmentContext = React.createContext({});
export const I18nSegment: React.FC<I18nSegmentProps> = ({variants, children}) => {
  return <I18nSegmentContext.Provider value={variants}>{children}</I18nSegmentContext.Provider>;
}

export function useTrans() {
  const variants = useContext(I18nSegmentContext);

  function translate(
    key: string,
    replacements?: Replacements,
    options?: I18nOptions
  ) {
    const res = t(key, {...variants, ...replacements}, options);
    return res;
  };

  function html_translate(
    key: string,
    replacements?: Replacements,
    options?: I18nOptions
  ) {
    const { nl2br } = options || {};
    const res = translate(key, replacements, options);
    if (nl2br) res.replace(/(?:\r\n|\r|\n)/g, '<br>');
    return <span dangerouslySetInnerHTML={{__html: res}} />
  };

  return { t: translate, h: html_translate };
}

export {
  getTranslations,
  I18n,
  l,
}