import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../app/features/auth';
import { selectProjects } from '../app/features/projects';
import { People as BasePeople } from '../components/Help/People';

interface Props {
    onBack: () => void;
}

export const People: React.FC<Props> = (props) => {
    const { onBack } = props;
    const user = useSelector(selectCurrentUser);
    const projects = useSelector(selectProjects);
    if (!user || !user.stats) {
        return null;
    }
    
    return (
        <BasePeople
            stats={user?.stats}
            items={user.stats.people}
            villages={user.stats.villages}
            projects={projects}
            onBack={onBack}
        />
    );
}