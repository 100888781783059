import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { cssVars } from '../../app/theme';

const StyledSegment = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    > svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        > polyline {
            stroke-dasharray: var(--stroke-dasharray, 1000);
            stroke-dashoffset: var(--stroke-dashoffset, 0);
            transition: stroke-dashoffset var(--speed, 2s) linear;
        }
    }
`;

const Figure = styled.figure`
    display: block;

    > img {
        max-width: 100%;
    }
`;

export interface SegmentProps {
    active?: boolean;
    file?: string;
    path?: [number, number][];
    speed?: number;
    canvas: [number, number];
    onCompleteAnimation?: () => void;
}

export const Segment: React.FC<SegmentProps> = (props) => {
    const { active, file, path, speed = 2000, canvas, onCompleteAnimation } = props;
    const prevActive = useRef<boolean | undefined>(false)

    useEffect(() => {
        if (active !== undefined && prevActive.current !== active) {
            window.setTimeout(() => {
                if (onCompleteAnimation) {
                    onCompleteAnimation();
                }
            }, speed);
        }
        prevActive.current = active;
    }, [active]);

    const length = useMemo(() => {
        if (path) {
            return path.reduce((acc, point, index) => {
                if (index === 0) return acc;
                const prev = path[index-1];
                return acc + Math.sqrt(Math.pow((point[0] - prev[0]), 2) + Math.pow((point[1] - prev[1]), 2));
            }, 0);
        }
        return 0;
    }, [path]);

    return <StyledSegment style={cssVars({'stroke-dasharray': length, 'stroke-dashoffset': active ? 0 : length, 'speed': `${speed/1000}s`, })}>
        {file && <Figure><img src={file} /></Figure>}
        {path && (
        <svg viewBox={`0 0 ${canvas.join(' ')}`}>
            <polyline points={path.map(p => p.join(' ')).join(',')} fill="none" stroke="#1da7e0" stroke-width="100" stroke-linejoin="round" />
        </svg>
        )}
    </StyledSegment>;
}