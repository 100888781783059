import React, { useCallback, useEffect, useState, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { asset } from '../../../app/helpers';
import { useSFX } from '../../../app/hooks/useSFX';
import { config } from '../../../app/services/config';
import { useTrans } from '../../../app/services/i18n';
import { IGameFindDiff, IGameFindDiffSecret } from '../../../types';
import { GameEvents, GameSecret } from '../Common';
import { Time } from '../Time';

const Header = styled.header`
    display: flex;
    font-size: 18px;
    font-weight: 700;
    height: 10vh;
    align-items: center;
    justify-content: center;

    > * {
        padding: 0 10px;
        &:first-child { color: #00AFAA; }
        &:last-child { color: #1DA7E0; }
    }
`;

const Wrapper = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    height: 100%;
`;

const Figure = styled.div`
    flex: 1;

    > span {
        position: relative;
        display: inline-block;

        > img {
            display: block;
            max-width: 100%;
            max-height: 45vh;
        }
    }

`;

interface StyledRoundSpotSpotProps {
    size?: number;
    x: number;
    y: number;
    ghost?: boolean;
    color?: string;
}

const RoundSpot = styled.div<StyledRoundSpotSpotProps>`
    ${props => props.ghost ? 'pointer-events: none;' : null}
    position: absolute;
    width: ${props => props.size}px;
    height: ${props => (props.size || 0)/2}px;
    left: ${props => props.x}%;
    top: ${props => props.y}%;
    transform: translate(-50%, -50%);
    border: 2px solid ${props => props.color || 'transparent'};
    border-radius: 100%;
`;

interface StyledRectSpotSpotProps {
    x: number;
    y: number;
    w: number;
    h: number;
    ghost?: boolean;
    color?: string;
}

const RectSpot = styled.div<StyledRectSpotSpotProps>`
    ${props => props.ghost ? 'pointer-events: none;' : null}
    position: absolute;
    width: ${props => props.w}%;
    height: ${props => props.h}%;
    left: ${props => props.x}%;
    top: ${props => props.y}%;
    background: ${props => props.color || 'transparent'};
`;

interface SpotProps  {
    coords: [number, number, number?, number?];
    size?: number;
    color?: string;
    ghost?: boolean;
    onClick?: (e: SyntheticEvent) => void;
}

export const Spot: React.FC<SpotProps> = (props) => {
    const { coords } = props;
    if (coords.length === 2) {
        // a round spot!
        return <RoundSpot {...props} x={coords[0]} y={coords[1]} />
    } else {
        // a rectangle spot!
        return <RectSpot {...props} x={coords[0]} y={coords[1]} w={coords[2]!} h={coords[3]!} />
    }
}

type evt = React.MouseEvent<HTMLImageElement, MouseEvent>;

function getMousePos( canvas: HTMLImageElement, evt: evt ) {
    var rect = canvas.getBoundingClientRect();
    return {
        x: Math.floor( ( evt.clientX - rect.left ) / ( rect.right - rect.left ) * canvas.width ),
        y: Math.floor( ( evt.clientY - rect.top ) / ( rect.bottom - rect.top ) * canvas.height )
    };
}

function getRelativeCoords(e: evt) {
    /*
    var rect = (e.target as HTMLImageElement)?.getBoundingClientRect();
    var x = e.clientX - rect.left; //x position within the element.
    var y = e.clientY - rect.top;
    console.log(e.target, y, e.clientY, rect.top)
    return { x, y };
    */
    return getMousePos(e.target as HTMLImageElement, e);
}

function getPercentCoords(e: evt) {
    const { x, y } = getRelativeCoords(e);
    const w = (e.target as HTMLImageElement)?.offsetWidth;
    const h = (e.target as HTMLImageElement)?.offsetHeight;
    return {
        x: x/w * 100,
        y: y/h * 100,
    }
}

function inRange( spot: [number, number], x: number, y: number ) {
    if (
        (x > spot[0] - TOLERANCE) && (x < spot[0] + TOLERANCE) &&
        (y > spot[1] - TOLERANCE) && (y < spot[1] + TOLERANCE)
    ) {
        return true;
    }
    return false;
}

const TOLERANCE = 8; // percent
const DROP_SECONDS = 5;

export interface FindDiffProps extends GameEvents, GameSecret<IGameFindDiffSecret>, IGameFindDiff {}

export const FindDiff: React.FC<FindDiffProps> = (props) => {
    const {timeout, original, modified, secret, onWin, onLoss} = props;
    const { spots } = secret;

    const { t } = useTrans();
    const sfx = useSFX();
    const [findings, setFindings] = useState<number[]>([]);
    const [drop, setDrop] = useState<number>(0);

    useEffect(() => {
        if (findings.length === spots.length) {
            onWin();
        }
    }, [findings, spots, onWin, onLoss]);

    const checkSpot = useCallback((event) => {
        /* @deprecated
        const { x, y } = getPercentCoords(event);
        // check againts spots
        const found = spots.reduce<number | null>((acc, spot, k) => {
            if (inRange(spot, x, y) && !findings.includes(k)) {
                return k;
            }
            return acc;
        }, null);
        if (found !== null) {
            // spotted!
            const newFindings = [...findings];
            newFindings.push(found);
            setFindings(newFindings);
            sfx.correct();
        } else {
            // missed
            setDrop(drop+1);
            sfx.wrong();
        }
        */
        
        setDrop(drop+1);
        sfx.wrong();
    }, [spots, findings, timeout, drop]);

    const clickSpot = useCallback((index, event) => {
        event.stopPropagation();
        if (findings.includes(index)) {
            return;
        }
        // spotted!
        const newFindings = [...findings];
        newFindings.push(index);
        setFindings(newFindings);
        sfx.correct();
    }, [findings]);

    const renderSpots = useCallback(() => {
        return <>
            {findings.map(index => {
                const spot = spots[index];
                const center = (spot.length > 2) ? [spot[0]+spot[2]!/2, spot[1]+spot[3]!/2] : spot;
                return <Spot key={index} size={20} coords={center as [number,number]} color="#fff000" ghost></Spot>
            })}
            {spots.map((spot, index) => {
                return <Spot key={index} size={10} coords={spot} color={config.debug ? "rgba(255,0,0,.2)" : undefined} onClick={(e) => clickSpot(index, e)}></Spot>
            })}
        </>;
    }, [findings, spots]);

    return (
        <>
            <Header>
                <div>{t('FindDiff.time')}: <Time timeout={timeout - drop*DROP_SECONDS} onZero={() => onLoss()} /></div>
                <div>{t('FindDiff.score')}: {findings.length} / {spots.length}</div>
            </Header>
            <Wrapper>
                <Figure>
                    <span>
                        {renderSpots()}
                        <img src={asset(original)} alt="Original" onClick={(event) => checkSpot(event)} />
                    </span>
                </Figure>
                <Figure>
                    <span>
                        {renderSpots()}
                        <img src={asset(modified)} alt="Modified" onClick={(event) => checkSpot(event)} />
                    </span>
                </Figure>
            </Wrapper>
        </>
    );
}
