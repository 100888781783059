import useSound from 'use-sound';
import correct_sfx from './sfx/correct.mp3';
import wrong_sfx from './sfx/wrong.mp3';
import win_sfx from './sfx/win.mp3';
import lose_sfx from './sfx/lose.mp3';

export function useSFX() {
    const [correct] = useSound(correct_sfx);
    const [wrong] = useSound(wrong_sfx);
    const [win] = useSound(win_sfx);
    const [lose] = useSound(lose_sfx);
    
    return {
        correct,
        wrong,
        win,
        lose
    }
}
