import { config } from './config';
import { http } from './http';
import { HttpError, ICode, IGame, IMatch, IMatchData } from '../../types';

export async function getGame(game_id: string): Promise<IGame> {
    try {
        const res = await http.request<IGame>({
            url: `${config.api}/api/games/show`,
            data: {
                game_id
            },
            withToken: true,
            tryCache: true,
        });
        return res.data;
    } catch(err) {
        throw err;
    }
}

export async function startMatch(game_id: string): Promise<IMatch> {
    try {
        const res = await http.request<IMatch>({
            url: `${config.api}/api/matches/start`,
            data: {
                game_id
            },
            withToken: true,
        });
        return res.data;
    } catch(err) {
        throw err;
    }
}

interface IMatchCompleteResponse {
    match_id: string;
    project_completed?: {
        code?: ICode;
    };
}

export async function completeMatch(token: string, success: boolean, matchData: IMatchData): Promise<IMatchCompleteResponse> {
    try {
        const res = await http.request<IMatchCompleteResponse>({
            url: `${config.api}/api/matches/complete`,
            data: {
                token,
                success,
                data: matchData
            },
            withToken: true,
        });
        return res.data;
    } catch(err) {
        throw err;
    }
}