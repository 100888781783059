import { config } from './config';
import { http } from './http';
import { IUser } from '../../types';

const CONSTANTS = {
    localStorageKey: 'jwt'
}

export function getToken() {
    const jwt = localStorage.getItem(CONSTANTS.localStorageKey);
    try {
        return jwt ? JSON.parse(jwt) : false;
    } catch(e) {
        return false;
    }
}

export function hasToken() {
    return !!getToken();
}

export async function loadUser(jwt?: string): Promise<IUser> {
    const _jwt = jwt || getToken();
    try {
        const res = await http.request<IUser>({
            url: `${config.api}/api/auth/me`,
            withToken: true,
            tryCache: true
        });
        return res.data;
    } catch(err) {
        throw err;
    }
}

export async function login(email: string, password: string): Promise<string> {
    try {
        const res = await http.request<string>({
            url: `${config.api}/api/auth/login`,
            data: {
                email,
                password
            }
        });
        const jwt = res.data;
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem(CONSTANTS.localStorageKey, JSON.stringify(jwt));
        return jwt;
    } catch(err) {
        throw err;
    }
}

export async function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(CONSTANTS.localStorageKey);
    return true;
}

export async function register(data: Partial<IUser>): Promise<string> {
    try {
        const res = await http.request<string>({
            url: `${config.api}/api/auth/register`,
            data
        });
        const jwt = res.data;
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem(CONSTANTS.localStorageKey, JSON.stringify(jwt));
        return jwt;
    } catch(err) {
        throw err;
    }
}

export async function socialLogin(provider: string, data: Partial<IUser>, providerData: any): Promise<string> {
    try {
        const res = await http.request<string>({
            url: `${config.api}/api/auth/socialLogin`,
            data: {
                provider,
                providerData,
                data
            }
        });
        const jwt = res.data;
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem(CONSTANTS.localStorageKey, JSON.stringify(jwt));
        return jwt;
    } catch(err) {
        throw err;
    }
}

export async function sendResetPasswordMail(email: string): Promise<string> {
    try {
        const res = await http.request<string>({
            url: `${config.api}/api/auth/sendResetPasswordMail`,
            data: {
                email,
            }
        });
        return res.data;
    } catch(err) {
        throw err;
    }
}

export interface setNewPasswordArgs { token: string, email: string, password: string, password_confirmation: string };

export async function setNewPassword(data: setNewPasswordArgs): Promise<string> {
    try {
        const res = await http.request<string>({
            url: `${config.api}/api/auth/setNewPassword`,
            data
        });
        return res.data;
    } catch(err) {
        throw err;
    }
}

/*
async function handleResponse(response: Response) {
    const text = await response.text();
    const data = text && JSON.parse(text);
    if (!response.ok) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            logout();
            // location.reload(true);
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
    return data;
}
*/