import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import * as projectsService from '../services/projects';
import { HttpError, ICode, ID, IError, IProject } from '../../types';

interface ProjectCompleted {
    gift_code?: ICode;
}

interface ProjectsState {
    loading: boolean;
    error?: IError;
    projects: IProject[];
    project: IProject | null;
    booted: ID[];
    completed: Record<string, ProjectCompleted>;
}

const initialState: ProjectsState = {
    loading: false,
    projects: [],
    project: null,
    booted: [],
    completed: {},
};

export const projectsReducer = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        loading: (state) => {
            state.loading = true;
        },
        gotProject: (state, action: PayloadAction<IProject>) => {
            state.project = action.payload;
            state.loading = false;
        },
        resetProject: (state) => {
            state.project = null;
        },
        gotProjects: (state, action: PayloadAction<IProject[]>) => {
            state.projects = action.payload;
            state.loading = false;
        },
        gotFailure: (state, action: PayloadAction<IError | undefined>) => {
            state.error = action.payload;
            state.loading = false;
        },
        gotProjectBooted: (state) => {
            if (state.project && !state.booted.includes(state.project.id)) state.booted.push(state.project.id);
        },
        gotProjectCompleted: (state, action: PayloadAction<ICode | undefined>) => {
            if (state.project && !state.completed[state.project.id]) state.completed[state.project.id] = { gift_code: action.payload };
        }
    },
});

export const { loading, gotProject, resetProject, gotProjects, gotFailure, gotProjectBooted, gotProjectCompleted } = projectsReducer.actions;

export const getProjectDetails = (project_id: string): AppThunk => dispatch => {
    dispatch(loading());

    projectsService.getProjectDetails(project_id)
    .then((data) => {
        dispatch(gotProject(data));
    }, (error: HttpError) => {
        dispatch(gotFailure(error.response?.data));
    });
}

export const getProjects = (): AppThunk => dispatch => {
    dispatch(loading());

    projectsService.getProjects()
    .then((data) => {
        dispatch(gotProjects(data));
    }, (error: HttpError) => {
        dispatch(gotFailure(error.response?.data));
    });
}

export const selectLoading = (state: RootState): boolean => state.projects.loading;
export const selectProjectDetails = (state: RootState): IProject | null => state.projects.project;
export const selectProjects = (state: RootState): IProject[] => state.projects.projects;
export const hasProjectBooted = (state: RootState): boolean => {
    if (!state.projects.project) return false;
    return state.projects.booted.includes(state.projects.project.id);
}
export const isProjectCompleted = (state: RootState): boolean => {
    if (!state.projects.project) return false;
    return !!state.projects.completed[state.projects.project.id];
}
export const projectCompletedData = (state: RootState): ProjectCompleted | undefined => {
    if (!state.projects.project) return undefined;
    return state.projects.completed[state.projects.project.id];
}
export const selectError = (state: RootState): IError | undefined => state.games.error;

export default projectsReducer.reducer