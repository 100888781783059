import React, { useEffect, useRef, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

interface TimeProps {
    timeout: number;
    stop?: boolean;
    onZero: () => void;
}

export const Time: React.FC<TimeProps> = (props) => {
    const { timeout, stop, onZero } = props;
    const [sec, setSec] = useState<number>(0);
    const started_at = useRef<number>();
    const timer = useRef<number>();

    useEffect(() => {
      started_at.current = +new Date();
      timer.current = window.setInterval(() => {
        const elapsed = Math.round((+new Date() - started_at.current!) / 1000);
        if (elapsed <= timeout) {
          setSec(elapsed);
        } else {
          onZero();
          clearInterval(timer.current);
        }
      }, 1000);
      return () => clearInterval(timer.current)
    }, [timeout]);

    useEffect(() => {
      if (stop) {
        clearInterval(timer.current);
      }
    }, [stop]);

    const elapsed = timeout - sec;

    return <>{elapsed >= 0 ? elapsed : 0}</>

    /*
    return <CountdownCircleTimer
    size={50}
    strokeWidth={3}
    isPlaying
    duration={timeout}
    colors={[
      ['#004777', 0.33],
      ['#F7B801', 0.33],
      ['#A30000', 0.33],
    ]}
    onComplete={onZero}
  >
    {({ remainingTime }: any) => remainingTime}
  </CountdownCircleTimer>;*/
}