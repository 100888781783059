import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import appReducer from './features/app';
import authReducer from './features/auth';
import projectsReducer from './features/projects';
import gamesReducer from './features/games';
import codesReducer from './features/codes';

var store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    projects: projectsReducer,
    games: gamesReducer,
    codes: codesReducer,
  },
});

export function getStore() {
  return store;
}

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;