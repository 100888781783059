import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import * as codesService from '../services/codes';
import { HttpError, IError, ICode } from '../../types';
import { clearPinnedCode, pinCode } from '../services/codes';

interface InsertedCodeResult extends ICode {
}

interface CodesState {
    inserting: boolean;
    error?: IError;
    insertedCode?: InsertedCodeResult;
    success: boolean;
    pinned_code?: string;
}

const initialState: CodesState = {
    inserting: false,
    success: false
};

export const codesReducer = createSlice({
    name: 'codes',
    initialState,
    reducers: {
        insertingCode: (state) => {
            state.inserting = true;
        },
        insertCodeSuccess: (state, action: PayloadAction<InsertedCodeResult>) => {
            state.inserting = false;
            state.insertedCode = action.payload;
            state.success = true;
        },
        insertCodeSuccessPurge: (state) => {
            state.inserting = false;
            state.insertedCode = undefined;
            state.success = false;
        },
        insertCodeFailure: (state, action: PayloadAction<IError | undefined>) => {
            state.error = action.payload;
            state.inserting = false;
        },
        insertCodeFailurePurge: (state) => {
            state.error = undefined;
        },
        rememberCode: (state, action: PayloadAction<string>) => {
            state.pinned_code = action.payload;
            pinCode(action.payload);
        },
        pinnedCodePurge: (state) => {
            state.pinned_code = undefined;
            clearPinnedCode();
        }
    },
});

export const { insertingCode, insertCodeSuccess, insertCodeSuccessPurge, insertCodeFailure, insertCodeFailurePurge, rememberCode, pinnedCodePurge } = codesReducer.actions;

export const insertCode = (code: string, callback?: (state: RootState) => void, error?: () => void): AppThunk => (dispatch, getState) => {
    dispatch(insertingCode());

    codesService.insertCode<InsertedCodeResult>(code)
    .then((result) => {
        dispatch(insertCodeSuccess(result));
        dispatch(pinnedCodePurge());
        if (callback) callback(getState());
    }, (error: HttpError) => {
        dispatch(insertCodeFailure(error.response?.data));
    });
}

export const selectInsertCodeSuccess = (state: RootState) => state.codes.insertedCode;
export const selectInsertCodeError = (state: RootState): IError | undefined => state.codes.error;
export const selectPinnedCode = (state: RootState) => state.codes.pinned_code;

export default codesReducer.reducer