import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { IUser } from '../../types';
import { config, session } from '../../app/services/config';
import { Input } from '../../components/Atoms/Input/Input';
import { Button, LinkButton, ButtonBar } from '../../components/Atoms/Button/Button';
import { logIn, socialLogin, selectError, dismissError } from '../../app/features/auth';
import SocialButton from './SocialButton';
import { Title, Text, Divider, Modal, IntroText } from '../../components/Modal/Modal';
import { Alert } from '../../components/Alert';
import { MODES } from './Auth';
import img_facebook from './facebook.svg';
import img_google from './google.svg';
import { changeLang, getCurrentLang, getLangs, useTrans } from '../../app/services/i18n';
import { Selector } from '../../components/Languages/Selector';

const LoginSubmit = styled.div`
    max-width: 200px;
    margin: 0 auto;
`;

const RegisterLink = styled.div`
    text-align: center;
    margin: 20px 0;
`

const LoginWith = styled.footer`
    > * {
        margin-bottom: 12px;
    }
    button {
        > span {
            flex: 1;
        }
    }
`;

export interface LoginProps {
    onChangeMode: (mode: MODES) => void;
}

interface FormState {
    email: string;
    password: string;
}

export const Login: React.FC<LoginProps> = ({onChangeMode}) => {
    const { t, h } = useTrans();
    const [form, setForm] = useState<Partial<FormState>>({});
    const error = useSelector(selectError);
    const dispatch = useDispatch();

    useEffect(() => {
        session.set('login_introduced', true);
    }, []);

    const hasIntro = useMemo(() => {
        return error && error.code === 102;
    }, [error])

    // input change
    const onChange = useCallback((key: string, value: string) => {
        setForm({...form, [key]: value});
    }, [form]);

    // classic login
    const onSubmit = useCallback(() => {
        dispatch(logIn(form.email!, form.password!));
    }, [form]);

    const onErrorPurge = useCallback(() => {
        dispatch(dismissError());
    }, []);

    // social login
    const handleSocialLogin = (provider: string) => (data: any) => {
        let user: Partial<IUser> = {};
        switch (provider) {
            case 'google':
                const _user = data._profile;
                user = {
                    name: _user.name,
                    email: _user.email,
                    picture: _user.profilePicURL,
                }
            break;
        }
        dispatch(socialLogin(provider, user, data._token));
    }

    const handleSocialLoginFailure = (provider: string) => (err: any) => {
        console.log(err)
    }

    return (
        <>
            {error && !hasIntro && <Alert delayClose={5000} onClose={onErrorPurge}>{t(error.error!)}</Alert>}
            {hasIntro && <Alert notClosable onClose={onErrorPurge}>
                {h('Login.newlogin_text')}
                <Divider />
                <ButtonBar>
                    <Button
                        text={t('Login.newlogin_btn')}
                        onPress={() => { onErrorPurge(); onChangeMode('forget_password'); }}
                    />
                </ButtonBar>
                <ButtonBar>
                    <Button
                        variant='action'
                        text={t('Login.newlogin_cancel')}
                        onPress={() => onErrorPurge()}
                    />
                </ButtonBar>
            </Alert>}
            <Modal id='Login' noHeader={true}>
                <Title>{t('Login.title')}</Title>
                <Text>
                    <IntroText>
                        {h('Login.text')}
                    </IntroText>
                </Text>
                <Input
                    label={"email"}
                    onChange={(val) => onChange('email', val)}
                />
                <Input
                    label={"password"}
                    type={"password"}
                    onChange={(val) => onChange('password', val)}
                />
                <LinkButton
                    text={t('Login.forgot_password')}
                    onPress={() => onChangeMode('forget_password')}
                />
                <Divider />
                <LoginSubmit>
                    <ButtonBar>
                        <Button
                            text={t('Login.submit')}
                            size='big'
                            onPress={() => onSubmit()}
                        />
                    </ButtonBar>
                </LoginSubmit>
                <RegisterLink>
                    <LinkButton
                        text={t('Login.register')}
                        onPress={() => onChangeMode('register')}
                    />
                </RegisterLink>
                <LoginWith>
                    <ButtonBar>
                        <SocialButton 
                            provider='facebook'
                            appId={config.oauth2_facebook || ""}
                            onLoginSuccess={handleSocialLogin('facebook')}
                            onLoginFailure={handleSocialLoginFailure('facebook')}
                            text={t('Login.login_facebook')}
                            icon={img_facebook}
                        />
                    </ButtonBar>
                    <ButtonBar>
                        <SocialButton 
                            provider='google'
                            appId={config.oauth2_google || ""}
                            onLoginSuccess={handleSocialLogin('google')}
                            onLoginFailure={handleSocialLoginFailure('google')}
                            text={t('Login.login_google')}
                            icon={img_google}
                        />
                    </ButtonBar>
                    <Divider />
                    <Selector
                        selected={getCurrentLang()}
                        languages={getLangs()}
                        onPress={(lang) => changeLang(lang)}
                    />
                </LoginWith>
            </Modal>
        </>
    );
}
