import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { config } from '../../app/services/config';
import { selectCurrentUser, loadUser } from '../../app/features/auth';
import { completeMatch, failurePurge, getGame, selectError, selectGame, selectLoading, selectMatch, startMatch } from '../../app/features/games';
import { Layer, LayerProps } from '../../components/Layer';
import { Quiz } from '../../components/Games/Quiz/Quiz';
import { Survey } from '../../components/Games/Survey/Survey';
import { TicTacToe } from '../../components/Games/TicTacToe/TicTacToe';
import { FindDiff } from '../../components/Games/FindDiff/FindDiff';
import { Memory } from '../../components/Games/Memory/Memory';
import { IMatchData } from '../../types';
import { GameEvents } from '../../components/Games/Common';
import { DebugBar } from '../../components/DebugBar';
import { WaterCatch } from '../../components/Games/WaterCatch/WaterCatch';
import { Story } from '../../components/Games/Story/Story';
import { CenteredSpinner } from '../../components/Spinner';
import { LauncherBackgrounds } from '../../components/Games/Launcher/Launcher';
import { I18nSegment } from '../../app/services/i18n';

const Wrapper = styled(Layer)<{background?: string}>`
    ${props => props.background ? `
        background-image: url(${props.background});
        background-size: cover;
    ` : null}
`;

const Content = styled.div<{padded: boolean}>`
    position: absolute;
    ${({padded}) => {
        const padding = padded ? 20 : 0;
        return `
            left: ${padding}px;
            right:${padding}px;
            top: ${padding}px;
            bottom: ${padding}px;
        `;
    }}
`;

const ContentFull = styled.div`
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
`;

interface GameLauncherRouteParams {
    project_id: string;
    game_id: string;
}

export interface GameLauncherProps extends LayerProps, GameEvents {}

export const GameLauncher: React.FC<GameLauncherProps> = (props) => {
    const { onWin, onLoss } = props;
    const history = useHistory();
    const params = useParams<GameLauncherRouteParams>();
    const loading = useSelector(selectLoading);
    const error = useSelector(selectError);
    const user = useSelector(selectCurrentUser);
    const game = useSelector(selectGame);
    const match = useSelector(selectMatch);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getGame(params.game_id));
        dispatch(startMatch(params.game_id));
    }, [params.game_id]);

    useEffect(() => {
        if (error) {
            back();
        }
    }, [error])

    const back = useCallback(() => {
        dispatch(failurePurge());
        history.push(`/projects/${params.project_id}`);
    }, [params.project_id]);

    if (!game || !match || loading) {
        return <CenteredSpinner />;
    }

    const gameCommonData = {
        secret: match.secret,
        onWin: (data: IMatchData) => {
            dispatch(completeMatch(match.token, {
                success: true,
                ...data
            }));
            onWin({
                no_modal: ['survey', 'story'].includes(game.type)
            });
            back();
        },
        onLoss: (data: IMatchData) => {
            dispatch(completeMatch(match.token, {
                success: false,
                ...data
            }));
            onLoss();
            back();
        }
    };
    
    return (
        <I18nSegment variants={{ project: params.project_id, game: game.id }}>
            <Wrapper z={props.z} grow background={LauncherBackgrounds['default'/*game.type*/]}>
                <Content padded={game.type !== 'water_catch'}>
                    {game.type === 'quiz' && <Quiz {...game.data} {...gameCommonData} />}
                    {game.type === 'survey' && <Survey {...game.data} {...gameCommonData} />}
                    {game.type === 'find_diff' && <FindDiff {...game.data} {...gameCommonData} />}
                    {game.type === 'memory' && <Memory icons_root={`projects/${params.project_id}/icons`} {...game.data} {...gameCommonData} />}
                    {game.type === 'tictactoe' && <TicTacToe {...gameCommonData} />}
                    {game.type === 'water_catch' && <WaterCatch {...gameCommonData} />}
                </Content>
                {game.type === 'story' && 
                    <ContentFull>
                        <Story
                            {...game.data}
                            onClose={gameCommonData.onWin}
                        />
                    </ContentFull>
                }
                {config.debug === true && <DebugBar><button onClick={() => gameCommonData.onWin({success: true})}>win</button><button onClick={() => gameCommonData.onLoss({success: false})}>loss</button></DebugBar>}
            </Wrapper>
        </I18nSegment>
    );
}
