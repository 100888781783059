import React, { useState } from 'react';
import styled from 'styled-components';
import { RoundButton } from '../Atoms/Button/Button';
import img_backpack from './backpack.svg';
import img_mask from './mask.svg';
import img_add from './add.svg';
import img_menu from './menu.svg';
import img_close from './close.svg';
import img_help from './help.svg';
import img_lvl_1 from './levels/1.svg';
import img_lvl_2 from './levels/2.svg';
import img_lvl_3 from './levels/3.svg';
import img_lvl_4 from './levels/4.svg';
import img_lvl_5 from './levels/5.svg';

const Wrapper = styled.header`
    position: relative;
    z-index: 3;
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;

    * + * {
        margin-left: 9px;
    }

    #add {
        margin-left: auto;
    }
`;

const DropDown = styled.div<{opened: boolean}>`
    position: absolute;
    z-index: 2;
    right: 13px;
    top: 90px;
    pointer-events: ${props => props.opened ? 'unset' : 'none '};

    * + * {
        margin-top: 9px;
    }
    
    > * {
        display: flex;
        transition: 1s transform, .2s opacity;
        
        opacity: ${props => props.opened ? 1 : 0};
        transform: translateY(${props => props.opened ? 0 : -100}px);

        &:first-child {
        }
        &:last-child {
            transition-delay: .1s;
        }
    }
`;

const Underlay = styled.div`
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
`;

export interface HeaderProps {
    mode?: string;
    level: number;
    action_add?: boolean;
    onButton: (type: string) => void;
}

const IMGS = [
    img_lvl_1, img_lvl_2, img_lvl_3, img_lvl_4, img_lvl_5
];

export const Header: React.FC<HeaderProps> = (props) => {
    const { mode, level, action_add = false, onButton } = props;
    const [opened, setOpened] = useState(false);

    return (
        <>
            <Wrapper>
                <RoundButton icon={mode === 'code:insert' ? img_close : img_add} color="#3DA05E" onPress={() => { setOpened(false); onButton('add'); }} />
                <RoundButton icon={mode === 'user:people' ? img_close : IMGS[level]} color="#0098DA" onPress={() => onButton('people')} />
                <RoundButton icon={mode === 'user:levels' ? img_close : img_backpack} color="#0098DA" onPress={() => onButton('levels')} />
                {action_add && <RoundButton id="add" icon={mode === 'app:help' ? img_close : img_menu} color="#00A19A" onPress={() => { setOpened(false); onButton('help'); }} />}
                {/*action_add && <RoundButton id="add" icon={opened ? img_close : img_menu} color="#00A19A" onPress={() => setOpened(!opened)} />*/}
            </Wrapper>
            <DropDown opened={opened}>
                <RoundButton size={48} icon={img_add} color="#3DA05E" onPress={() => { setOpened(false); onButton('add'); }} />
                <RoundButton size={48} icon={img_help} color="#0098DA" onPress={() => { setOpened(false); onButton('help'); }} />
            </DropDown>
            {opened && <Underlay onClick={() => setOpened(false)} />}
        </>
    );
}
